<template>
    <b-col sm="12" class="altura">
      <span class="alinhar_centro"><h4>{{responseTypes[mensagemTipo].infoTextUpper}}<br>{{responseTypes[mensagemTipo].infoTextLower}}</h4><br></span>
    </b-col>
  </template>
  
  <script>
  import { BCol } from 'bootstrap-vue'
  
  export default {
    components: {
      BCol,
    },
  
    props: {
        mensagemTipo: {
            required: true,
            type: Number
        },
    },
  
      data() {
        return {
            responseTypes: [
                {
                    'case': 'insuficienteColaboradores',
                    'infoTextUpper': `Não há dados a serem exibidos.`,
                    'infoTextLower': `Ainda não há informações cadastradas suficientes para exibição desta informação.`
                },
                {
                    'case': 'erroDesconhecido',
                    'infoTextUpper': `Visualização indisponível no momento.`,
                    'infoTextLower': `Tente novamente mais tarde ou entre em contato com o suporte.`
                }
            ],
        }
    },
  
  }
  </script>
  <style scoped>
    .altura{
        display: table;
        height: 297px;
        width: 100%;
        text-align: center;
    }
    .alinhar_centro {
    display: table-cell;
    vertical-align: middle;
    }
 
    @media (min-width: 1601px) and (max-width: 1603px) {
        .altura{
            height: 315px !important;
        }
    }
    @media (min-width: 1441px) and (max-width: 1600px) {
        .altura{
            max-height:343px !important;
            height: 343px !important;
        }
    }
    @media (min-width: 1327px) and (max-width: 1440px) {
        .altura{
            max-height:295px !important;
        }
    }
    @media (min-width: 1200px) and (max-width: 1327px) {
        .altura{
        max-height:277px !important;
        }
    }
    @media (max-width: 1600px) {
        .altura{
        max-height:240px;
        }
    }
    @media (max-width: 1199px) {
        .altura{
        max-height:340px;
        }
    }
    @media (min-width: 992px) and (max-width: 1067px) {
        .altura{
            height: 285px ;
        }
    }
    @media (max-width: 420px) {
        .altura{
        max-height: 300px;
        }
    }
    @media (max-width: 380px) {
        .altura{
        max-height: 270px;
        }
    }
    @media (max-width: 350px) {
        .altura{
        max-height: 240px;
        }
    }
  </style>
  