<template>
    <div v-if="dados && semRespostaNovaPergunta.total > 0">
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
      />
      <vue-perfect-scrollbar class="scroll-area p-2" :settings="settings">
        <div
          v-for="(dados, id) in dadosParaOComponenteFuncionar" 
          :key="id"
          class="
            employee-task
            d-flex
            justify-content-between
            align-items-center
            mr-1
            mb-1
          "
          style="min-width: 200px;"
          >

          <b-media no-body>
            <b-media-body class="my-auto">
              <h6 class="mb-0 fonte">
                {{ dados.conteudo }}
              </h6>
            </b-media-body>
          </b-media>

          <div class="d-flex align-items-center ml-1">
            <small class="text-muted mr-50 fonte">{{porcentagemPorDoisSemNulo(dados.totalizador,dados.filtro)}}%</small>
            <vue-apex-charts
              type="radialBar"
              height="30"
              width="30"
              :options="chartData[id].options ? chartData[id].options : {}"
              :series="chartData[id].series ? chartData[id].series : {}"
            />
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div
        v-else
        class="height-custom d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
  </template>

  <script>

  import {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BButton,
    BRow,
  } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "@/views/components/custom/dashboard-saude-mental/phq-9/CardSemDados.vue";


  const $trackBgColor = "#e9ecef";
  const $fillColor = "#7030a0";
  export default {
    components: {
      BMedia,
      BMediaAside,
      BMediaBody,
      BAvatar,
      VueApexCharts,
      VuePerfectScrollbar,
      BOverlay,
      CardSemDados,
      BButton,
      BRow,
    },
    props: {
      FaseSelecionada: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
      semRespostaNovaPergunta: {
        type: Object,
        default: false,
      },
      dados:{
        type: Array,
        required: true
      }
    },
    data() {
      return {
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0,
        },
        dadosPadrao: [
            {
                "conteudo": "Relacionamento com colegas",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Relacionamento com liderança",
                "totalizador": 0,
                "filtro": "0"
            },
          {
                "conteudo": "Disposiçaõ para o trabalho",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Produtividade",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Sentimento de Valorização",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Sobrecarga",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Crescimento Profissional",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Recursos de Trabalho",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Segurança no ambiente de trabalho",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Comunicação",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Clareza das funcções e responsabilidades",
                "totalizador": 0,
                "filtro": "0"
            },
            {
                "conteudo": "Assédio",
                "totalizador": 0,
                "filtro": "0"
            },



        ],
        verificaNovaPerguntaSaudeMentalTotal: null,
        loadingShow: true,
        tipoOrdemAlfa: true,
        dadosParaOComponenteFuncionar: [],
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.3,
          wheelPropagation: false,
        },
        chartData: [],
        chart: {
          series: [0],
          options: {
            grid: {
              show: false,
              padding: {
                left: -15,
                right: -15,
                top: -12,
                bottom: -15,
              },
            },
            colors: [$fillColor],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "22%",
                },
                track: {
                  background: $trackBgColor,
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  },
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
          },
        },
      };
    },

    mounted() {
      this.buscaDadosMaiorDificuldade();
      this.loadingShow = false;
    },

    methods: {
      buscaDadosMaiorDificuldade() {
        if(this.semRespostaNovaPergunta.total > 0 && this.$props.dados.length == 0){
          this.dadosParaOComponenteFuncionar = this.dadosPadrao;
        }else{
          this.dadosParaOComponenteFuncionar = this.$props.dados;
        }
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
      },
      adicionarCampoPoercent() {
        this.dadosParaOComponenteFuncionar.map((item, index) => {
          this.dadosParaOComponenteFuncionar[index].percent =
            this.porcentagemPorDoisSemNulo(item.totalizador, item.filtro);
        });
      },

      verificaNovaPerguntaSaudeMental(){
        let params = {
          idEmpresa: this.FaseSelecionada.id_empresa,
          idFase: this.FaseSelecionada.id_fase
        };

      this.$http
        .get(this.$api.VerificaNovaPerguntaSaudeMental, { params })
        .then((res) => {
            this.verificaNovaPerguntaSaudeMentalTotal = res.data[0];
        })
    },

      ordenarAlfa(asc) {
        this.tipoOrdemAlfa = asc;
        this.adicionarCampoPoercent();
        Promise.all(
          this.dadosParaOComponenteFuncionar.sort((a, b) => {
            if (asc) {
              if (a.nome < b.nome) return -1;
              if (a.nome > b.nome) return 1;
            } else {
              if (a.nome > b.nome) return -1;
              if (a.nome < b.nome) return 1;
            }
            return 0;
          })
        ).then(() => {
          this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
        });
      },

      ordenarPorcentAsc() {
        this.adicionarCampoPoercent();
        Promise.all(
          this.dadosParaOComponenteFuncionar.sort((a, b) => {
            if (parseInt(a.percent) < parseInt(b.percent)) return -1;
            if (parseInt(a.percent) > parseInt(b.percent)) return 1;
            return 0;
          })
        ).then(() => {
          this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
        });
      },
      criacaoPreenchimentoCharts(dadosCharts) {
        this.chartData = [];
        for (const element of dadosCharts) {
          const chartClone = JSON.parse(JSON.stringify(this.chart));
          const porcentagem = this.porcentagemPorDoisSemNulo(
            element.totalizador,
            element.filtro
          );
          const codigoCor = this.condicaoCor(porcentagem);
          chartClone.options.colors[0] = codigoCor;
          chartClone.series[0] = porcentagem;
          this.chartData.push(chartClone);
        }
      },
      condicaoCor(porcentagem) {
        if (porcentagem == 0 || porcentagem == "") {
          return "#e9ecef";
        } else if (porcentagem > 0 && porcentagem <= 19) {
          return "#AA228C";
        } else if (porcentagem >= 20 && porcentagem <= 44) {
          return "#002060";
        } else if (porcentagem >= 45 && porcentagem <= 74) {
          return "#2772C0";
        } else if (porcentagem >= 75 && porcentagem <= 99) {
          return "#7030A0";
        } else if (porcentagem == 100) {
          return "#36BA00";
        }
      },
      porcentagemPorDoisSemNulo(valorX, valorY) {
        if (valorY != 0) {
          return ([valorX / valorY] * 100).toFixed(0);
        } else {
          return '0';
        }
      },
    },
    watch: {
      FaseSelecionada: function () {
        this.buscaDadosMaiorDificuldade();
      },
    },
  }
  </script>

<style scoped>

    .ordenacao{
        justify-content: right;
        display: flex;
        margin: 5px;
     }
    .alfaBtn{
        padding: 5px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 0px;
    }
    .percentBtn{
        padding: 5px;
        border-radius: 0px;
    }

    .scroll-area{
      height: 245px;
    }

    .card-employee-task{
      height: 340px;
    }

    @media (min-width: 1336px) and (max-width: 1775px){
      .scroll-area{
        height: 230px;
      }
    }

    @media (min-width: 768px) and (max-width: 1500px){
      .fonte{
        font-size: 10px;
      }
    }

    @media (min-width: 1200px) and (max-width: 1335px){
      .fonte{
        font-size: 11px;
      }
    }

    @media (min-width: 1071px) and (max-width: 1199px){
      .scroll-area{
        height: 240px;
      }
    }

    @media (min-width: 992px) and (max-width: 1400px){
      .scroll-area{
        height: 200px;
      }
    }


    @media (min-width: 768px) and (max-width: 991px){
      .scroll-area{
        height: 215px;
      }
    }

    @media (min-width: 768px) and (max-width: 900px){
      .fonte{
        max-width: 100px;
      }
    }
</style>

