<template>
  <vue-apex-charts
    id="chart"
    ref="myChart"
    type="bar"
    height="100%"
    width="100%"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      series: this.$props.dados,
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
          height: '100%',
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: '400'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '80%'
          }
        },
        colors: [function({ dataPointIndex, w }) {
          if(w.globals.labels[dataPointIndex] === 'Ansiedade') {
            return '#2772C0'
          }else if(w.globals.labels[dataPointIndex] == 'Depressão'){
            return '#002060'
          }else if(w.globals.labels[dataPointIndex] == 'Estresse'){
            return '#7030A0'
          }else if(w.globals.labels[dataPointIndex] == 'Sem sintomas'){
            return '#36BA00'
          }else{
            return '#000000'
          }
        }],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 7,
          colors: ['transparent']
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            style: {
              colors: '#B9B9C3',
              fontSize: "12px"
            }
          }
        },
        xaxis: {
          categories: ['Ansiedade', 'Depressão', 'Estresse', 'Sem sintomas'],
          crosshairs: {
            show: false
          },
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            trim: true,
            style: {
              colors: '#B9B9C3',
              fontSize: "12px"
            },
          },
          axisTicks: {
            show: false
          },
        },
        legend: {
          show: false
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          custom: function({ seriesIndex, w }) {
            return (
              '<div '+
                'style="background-color: #82868B;font-size: 0.857rem;font-weight: 400;line-height: 1.45;color: white;padding: 5px 10px;"' +
              '>' +
                w.config.series[seriesIndex].name +
              "</div>"
            );
          }
        },
        responsive:[
          {
            breakpoint:991,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px"
                  },
                }
              }
            }
          }
        ]
      }
    };
  }
};
</script>
<style>
.tooltip-teste{
  background-color: #82868B;
  font-size: 0.857rem;
  font-weight: 400;
  line-height: 1.45;
  color: white;
  padding: 5px 10px;
}
</style>
