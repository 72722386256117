<template>
  <div>
    <div
      v-if="dados && dados.length > 0"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <div
        v-if="!loadingShow"
        class="height-custom px-2 mt-1 pb-md-0 pb-1"
      >
        <b-row
          class="no-gutters"
        >
          <b-col
            cols="6"
            class="pr-1"
          >
            <div
              class="genderTitle mb-2"
            >
              {{ dadosOrganizados[0]['nomeGenero'] }}
            </div>
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][0].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][0].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][0].totalDoenca, dadosOrganizados[0]['doencas'][0].totalResposta)"
              :barColor="'#2772C0'"
              :refKey="1"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][1].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][1].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][1].totalDoenca, dadosOrganizados[0]['doencas'][1].totalResposta)"
              :barColor="'#002060'"
              :refKey="2"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][2].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][2].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][2].totalDoenca, dadosOrganizados[0]['doencas'][2].totalResposta)"
              :barColor="'#7030A0'"
              :refKey="3"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[0]['doencas'][3].doenca"
              :barAbsoluteNumber="dadosOrganizados[0]['doencas'][3].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[0]['doencas'][3].totalDoenca, dadosOrganizados[0]['doencas'][3].totalResposta)"
              :barColor="'#36BA00'"
              :refKey="4"
            />
          </b-col>
          <b-col
            cols="6"
            class="pl-1"
          >
            <div
              class="genderTitle mb-2"
            >
              {{ dadosOrganizados[1]['nomeGenero'] }}
            </div>
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][0].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][0].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][0].totalDoenca, dadosOrganizados[1]['doencas'][0].totalResposta)"
              :barColor="'#2772C0'"
              :refKey="1"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][1].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][1].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][1].totalDoenca, dadosOrganizados[1]['doencas'][1].totalResposta)"
              :barColor="'#002060'"
              :refKey="2"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][2].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][2].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][2].totalDoenca, dadosOrganizados[1]['doencas'][2].totalResposta)"
              :barColor="'#7030A0'"
              :refKey="3"
              class="mb-1"
            />
            <respostas-por-genero-titulo-grafico
              :barTitle="dadosOrganizados[1]['doencas'][3].doenca"
              :barAbsoluteNumber="dadosOrganizados[1]['doencas'][3].totalDoenca"
              :barPercentage="preparePorcentage(dadosOrganizados[1]['doencas'][3].totalDoenca, dadosOrganizados[1]['doencas'][3].totalResposta)"
              :barColor="'#36BA00'"
              :refKey="4"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import RespostasPorGeneroTituloGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/RespostasPorGeneroTituloGrafico.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    CardSemDados,
    RespostasPorGeneroTituloGrafico
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      dadosOrganizados: [
        {
          'nomeGenero': 'Feminino',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        },
        {
          'nomeGenero': 'Masculino',
          'doencas': [
            {
              'doenca': 'Ansiedade',
              'totalDoenca': 0,
              'totalResposta': 0
            },
            {
              'doenca': 'Depressão',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Estresse',
              'totalDoenca': 0,
              'totalResposta': 0,
            },
            {
              'doenca': 'Sem sintomas',
              'totalDoenca': 0,
              'totalResposta': 0
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.organizaDados()
  },
  methods: {
    organizaDados(){
      for (const element of this.$props.dados) {
        if(element.genero === 'Feminino'){
          this.organizaDadosDoencas(0, element)
        }else if(element.genero === 'Masculino'){
          this.organizaDadosDoencas(1, element)
        }
      }
      this.loadingShow = false
    },
    organizaDadosDoencas(indexArrayValue, arrayElement){
      if(arrayElement.doenca === 'Ansiedade'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][0],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Depressão'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][1],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Estresse'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][2],
          arrayElement
        )
      }else if(arrayElement.doenca === 'Sem Sintomas'){
        this.organizaNumerosDoencas(
          this.dadosOrganizados[indexArrayValue]['doencas'][3],
          arrayElement
        )
      }
    },
    organizaNumerosDoencas(arrayDoenca, arrayElement){
      arrayDoenca.totalDoenca = arrayElement.total_doenca
      arrayDoenca.totalResposta = arrayElement.total_resposta
    },
    preparePorcentage(value1, value2){
      return ((value1/value2) * 100).toFixed(2).toString();
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 252px;
}
.genderTitle{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1rem;
  }
}
</style>
