<template>
  <div v-if="dados && dados.length > 0">
    <b-overlay 
      v-if="loadingShow"
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    />
    <div class="p-2">
      <b-row>
        <b-col
          v-for="(item, id) in statisticsItems"
          :key="id"
          xl="6"
          lg="6"
          md="12"
          class="pb-1"
          :class="item.customClass"
        >
          <b-col
            xl="12"
            lg="12"
            class="titulo_espacamento"
            :class="item.customTitulo"
          >
            <strong class="text-muted titulo_tamanho_responsivo">{{
              item.titulo
            }}</strong>
          </b-col>
          <b-col
            xl="12"
            lg="12"
            class="centralizar_icone_porcentagem"
            :class="item.customIcone"
          >
            <span>
              <img
                :src="item.icone"
                :alt="item.altIcone"
                width="48"
                height="48"
              />
            </span>
            <span class="porcentagemEstatisticas">
              {{ item.porcentagem }}
            </span>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
  <div
    v-else
    class="height-custom d-flex align-items-center justify-content-center"
  >
    <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
  </div>
    
  
</template>

<script>
import {
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
  BCardTitle,
} from "bootstrap-vue";
import IconeCubo from "@/assets/custom-icons/cubo.svg";
import IconeOms from "@/assets/custom-icons/oms.svg";
import IconeBrasil from "@/assets/custom-icons/brasil.svg";
import IconeS from "@/assets/custom-icons/iconeS.svg";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/phq-9/CardSemDados.vue";

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
    BCardTitle,
    CardSemDados
  },
  setup() {
    return {
      IconeCubo,
      IconeOms,
      IconeBrasil,
      IconeS,
    };
  },
  props: {
    DataCicloTempoReal: {
      type: String,
      default() {
        return {};
      },
      required: true,
    },
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loadingShow: true,
      dadosParaOComponenteFuncionar: [],
      validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0
        },
      statisticsItems: [
        {
          icone: IconeCubo,
          altIcone: "minha-empresa",
          porcentagem: 0,
          customClass: "bg-light rounded",
          customTitulo: "tituloMinhaEmpresa",
          customIcone: "iconeMinhaEmpresa",
          titulo: "MINHA EMPRESA",
        },
        {
          icone: IconeOms,
          altIcone: "oms",
          porcentagem: "5,8%",
          customClass: "",
          titulo: "ORGANIZAÇÃO MUNDIAL DA SAÚDE",
        },
        {
          icone: IconeBrasil,
          altIcone: "brasil",
          porcentagem: "10,2%",
          customClass: "",
          titulo: "PESQUISA DE SAÚDE BRASIL",
        },
        {
          icone: IconeS,
          altIcone: "sul",
          porcentagem: "15,2%",
          customClass: "",
          titulo: "PESQUISA DE SAÚDE REGIÃO SUL",
        },
      ],
    };
  },
  beforeMount() {
    this.buscaDadosCardEstatisticasDepressao();

    this.loadingShow = false;
  },
  methods: {
    buscaDadosCardEstatisticasDepressao() {
        if (this.$props.dados == 0) {
          this.statisticsItems[0].porcentagem = "";
        } else {
          this.dadosParaOComponenteFuncionar = this.$props.dados;
          this.statisticsItems[0].porcentagem =
            this.calculoPorcentagemMinhaEmpresa(
              this.dadosParaOComponenteFuncionar
            ) + "%";
        }
    },
    calculoPorcentagemMinhaEmpresa(dadosParaOComponenteFuncionar) {
      let calculoMinhaEmpresa = 0;
      let arrayExistencia = this.validarExistencia(
        dadosParaOComponenteFuncionar
      );
      if (arrayExistencia[0]) {
        calculoMinhaEmpresa = this.calculoFormula(
          dadosParaOComponenteFuncionar,
          arrayExistencia[1]
        );
      }
      return calculoMinhaEmpresa.toString().replace(".", ",");
    },
    validarExistencia(dadosParaOComponenteFuncionar) {
      let validador = false;
      let totalizadorAlto = 0;
      for (const element of dadosParaOComponenteFuncionar) {
        if (
          element["escala_depresao"] == "alta" &&
          element["totalizador"] > 0
        ) {
          validador = true;
          totalizadorAlto = element["totalizador"] * 100;
        }
      }
      return [validador, totalizadorAlto];
    },
    calculoFormula(dadosParaOComponenteFuncionar, valorDivisao) {
      let sum = 0;
      for (const element of dadosParaOComponenteFuncionar) {
        sum += parseFloat(element["totalizador"]);
      }
      return (valorDivisao / sum).toFixed(1);
    },
  },
};
</script>
<style scoped>
  .porcentagemEstatisticas{
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }
  .centralizar_icone_porcentagem{
    text-align: center;
  }

  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .titulo_espacamento{
    height: 70px;
    text-align:center;
    padding-top: 5px;
  }

  .tituloMinhaEmpresa{
    padding-top: 25px;
  }

  .margem-subtitulo_responsivo{
    margin: 5px 0 0 auto;
  }

@media (max-width: 1600px) {
  .titulo_espacamento{
    height: 80px;
  }
  .tituloMinhaEmpresa{
    padding-top: 25px;
  }
  .centralizar_icone_porcentagem{
    margin-top:5px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .col-xl-3{
    flex:0 0 50%;
    max-width:50%;
  }

  .porcentagemEstatisticas{
    font-size: 18px;
  }
 
  .tituloMinhaEmpresa{
    padding-top: 5px;
  }
  .centralizar_icone_porcentagem{
    margin-top:-5px;
    min-width: 137px ;
  }
}
@media (max-width: 1199px) {
  .tituloMinhaEmpresa{
    padding-top: 15px;
  }
  .centralizar_icone_porcentagem{
    margin-top:-15px;
  }
}
@media (max-width: 991px) {
  .centralizar_icone_porcentagem{
    margin-top:-25px;
  }
}
</style>
