<template>
  <div class="text-center px-2">
    <div>
      <h5>{{responseTypes[mensagemTipo].infoTextUpper}}</h5>
    </div>
    <div>
      <h5>{{responseTypes[mensagemTipo].infoTextLower}}</h5>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    mensagemTipo: {
        required: true,
        type: Number
    },
  },

  data() {
    return {
      responseTypes: [
        {
          'case': 'insuficienteColaboradores',
          'infoTextUpper': `Não há dados a serem exibidos.`,
          'infoTextLower': `Ainda não há informações cadastradas suficientes para exibição desta informação.`
        },
        {
          'case': 'erroDesconhecido',
          'infoTextUpper': `Visualização indisponível no momento.`,
          'infoTextLower': `Tente novamente mais tarde ou entre em contato com o suporte.`
        }
      ],
    }
  },
}
</script>
