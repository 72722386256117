<template>
    <canvas
      ref="myChart"
    ></canvas>
  </template>
  
  <script>
  import Chart from 'chart.js';
  Chart.defaults.global.legend.display = false;
  Chart.defaults.global.legend.labels.usePointStyle = true; 
  
  export default {
    props: {
      dados: {
        type: Array,
        required: true
      }
    },
    data(){
      return {
        myChart: null,
        sumRespostas: 0,
        preparedData: [
          {
            nomeDoenca: "Sem sintomas",
            totalEscala: 0
          },
          {
            nomeDoenca: "Sintomas minimos",
            totalEscala: 0
          },
          {
            nomeDoenca: "Sintomas leves",
            totalEscala: 0
          },
          {
            nomeDoenca: "Sintomas moderados",
            totalEscala: 0
          },
          {
            nomeDoenca: "Sintomas graves",
            totalEscala: 0
          }
        ]
      };
    },
    mounted() {
      this.prepareData()
    },
    methods: {
      prepareData(){
        this.sumRespostas = this.$props.dados[0].filtro
        for (const element of this.$props.dados) {
          if(element.escala === 0){
            this.preparedData[0].totalEscala = element.totalizador
          }else if(element.escala === 1){
            this.preparedData[1].totalEscala = element.totalizador
          }else if(element.escala === 2){
            this.preparedData[2].totalEscala = element.totalizador
          }else if(element.escala === 3){
            this.preparedData[3].totalEscala = element.totalizador
          }
          else if(element.escala === 4){
            this.preparedData[4].totalEscala = element.totalizador
          }
        }
        
        this.renderChart()
      },
      renderChart() {
        if (this.myChart) this.myChart.destroy();
        const ctx = this.$refs.myChart.getContext('2d');
        const config = this.getChartConfig();
        this.myChart = new Chart(ctx, config);
        const sumRespostas = this.sumRespostas;
        Chart.pluginService.register({
          beforeDraw: function (chart) {
            if (chart.config.type === 'doughnut') {
              let width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.chart.ctx;
              ctx.clearRect(0, 0, width, height);
  
              ctx.font = " 27px Montserrat";
              ctx.fillStyle = "#5E5873";
  
              let text = sumRespostas,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 1.9;
              ctx.fillText(text, textX, textY);
              ctx.save();
  
              width = chart.chart.width
              height = chart.chart.height
              ctx = chart.chart.ctx
  
              ctx.font = "10px Montserrat";
              ctx.fillStyle = "#B9B9C3";
  
              text = "Respostas"
              textX = Math.round((width - ctx.measureText(text).width) / 2)
              textY = height / 1.6
  
              ctx.fillText(text, textX, textY);
              ctx.save();
            }
          }
        });
      },
      getChartConfig() {
        const data = {
            labels: ['Sem sintomas', 'Sintomas minimos', 'Sintomas leves', 'Sintomas moderados', 'Sintomas graves'],
            datasets: [
              {
                label: 'dataset do gráfico de detalhamento por área',
                data: [
                  this.preparedData[0].totalEscala,
                  this.preparedData[1].totalEscala,
                  this.preparedData[2].totalEscala,
                  this.preparedData[3].totalEscala,
                  this.preparedData[4].totalEscala
                ],
                backgroundColor: ['#36BA00', '#2772C0', '#002060', '#7030A0', '#AA228C'],
                borderWidth: 3
              },
            ]
        };
        const config = {
          type: 'doughnut',
          data: data,
          options: {
            cutoutPercentage: 65,
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                }
              },
              title: {
                display: false,
                text: 'Gráfico de escala de respostas',
              },
            },
            tooltips: {
              usePointStyle: true,
              pointStyle: 'circle',
              backgroundColor: '#FFF',
              titleFontColor: '#5E5873',
              bodyFontColor: '#5E5873',
              borderColor: "#2772C0",
              borderWidth: 1,
              titleFontFamily: "Montserrat",
              titleFontSize: 11,
              bodyFontFamily: "Montserrat",
              bodyFontSize: 10,
              cornerRadius: 6,
            },
          },
          plugin: [
            {
              id: 'my-doughnut-text-plugin',
              afterDraw: function (chart) {
                let theCenterText = "90%";
                const canvasBounds = canvas.getBoundingClientRect();
                const fontSz = Math.floor(canvasBounds.height * 0.10);
                chart.ctx.textBaseline = 'middle';
                chart.ctx.textAlign = 'center';
                chart.ctx.font = fontSz + 'px Arial';
                chart.ctx.fillText(theCenterText, canvasBounds.width / 2, canvasBounds.height * 0.70)
              }
            }
          ]
        };
        
        return config;
      }
    },
  };
  </script>

<style scoped>
  canvas{
    height: 280px !important;
    width: 280px !important;
  }

  @media (max-width: 905px){
    canvas{
      height: 240px !important;
      width: 240px !important;
    }
  }
</style>
  