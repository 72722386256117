<template>
  <vue-apex-charts
    type="radialBar"
    class="margem-grafico-responsiva"
    :options="graficoGoalOverviewRadialBar.chartOptions"
    :series="graficoGoalOverviewRadialBar.series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  props: {
    dados: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      graficoGoalOverviewRadialBar: {
        series: [],
        chartOptions: {},
        graphHeight: 300,
        percentageFontSize: "37px",
        completeFontSize: "13px"
      }
    };
  },
  mounted() {
    this.prepareResponsiveSizes()
    window.addEventListener('resize', () => {
      this.prepareResponsiveSizes()
    });
    this.graphPrepare()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.prepareResponsiveSizes);
  },
  methods: {
    graphPrepare(){
      this.graficoGoalOverviewRadialBar.series = this.graphSeries();
      this.graficoGoalOverviewRadialBar.chartOptions = this.graphProps();
    },
    prepareTooltip(){
      return (
        '<div '+
          'style="background-color: #82868B;font-size: 0.857rem;font-weight: 400;line-height: 1.45;color: white;padding: 5px 10px;"' +
        '>' +
          '<div>' + this.calculoPorcentagemTooltip(this.$props.dados.recusas,this.$props.dados.participantes) + '% Recusas</div>' +
          '<div>' + this.calculoPorcentagemTooltip(this.$props.dados.respostas, this.$props.dados.participantes) + '% Respostas</div>' +
        '</div>'
      );
    },
    graphSeries(){
      return [
        (
          ((this.$props.dados.respostas + this.$props.dados.recusas) /
            this.$props.dados.participantes) *
          100
        ).toFixed(0),
      ];
    },
    calculoPorcentagemTooltip(valor, participantes) {
      let porcentagem = ((valor / participantes) * 100).toFixed(0);
      if (porcentagem == 0) {
        porcentagem = this.toFixed((valor / participantes) * 100, 1);
        if (porcentagem == 0.0) {
          porcentagem = ((valor / participantes) * 100).toFixed(0);
        }
      }
      return porcentagem;
    },
    graphProps(){
      return {
        chart: {
          height: this.graficoGoalOverviewRadialBar.graphHeight,
          type: "radialBar",
        },
        colors: ["#B05CBE", "#7030A0"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -125,
            endAngle: 125,
            track: {
              background: "#ebe9f1",
              strokeWidth: "40%",
              startAngle: -125,
              endAngle: 125,
            },
            hollow: {
              size: "75%",
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: 25,
              },
              value: {
                offsetY: -15,
                color: "#5E5873",
                fontFamily: "Montserrat",
                fontSize: this.graficoGoalOverviewRadialBar.percentageFontSize,
                fontWeight: "500",
              },
              total: {
                show: true,
                label: "Completo",
                fontSize: this.graficoGoalOverviewRadialBar.completeFontSize,
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#B9B9C3",
              }
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            gradientToColors: ["#421F8D"],
            inverseColors: false,
            stops: [50]
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          custom: this.prepareTooltip
        }
      };
    },
    prepareResponsiveSizes(){
      if (window.innerWidth > 767 && window.innerWidth <= 993){
        this.graficoGoalOverviewRadialBar.percentageFontSize = "30px"
        this.graficoGoalOverviewRadialBar.completeFontSize = "11px"
        this.graficoGoalOverviewRadialBar.graphHeight = 200
      }else if(window.innerWidth > 993 && window.innerWidth < 1350){
        this.graficoGoalOverviewRadialBar.percentageFontSize = "32px"
        this.graficoGoalOverviewRadialBar.completeFontSize = "13px"
        this.graficoGoalOverviewRadialBar.graphHeight = 250
      }else{
        this.graficoGoalOverviewRadialBar.percentageFontSize = "37px"
        this.graficoGoalOverviewRadialBar.completeFontSize = "13px"
        this.graficoGoalOverviewRadialBar.graphHeight = 300
      }
      this.graphPrepare()
    },
    toFixed(num, fixed) {
      let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    }
  },
  watch: {
    dados: function() {
      this.graphPrepare();
    }
  }
};
</script>
