<template>
  <b-row class="no-gutters link-full-screen d-flex justify-content-center align-items-center">
    <b-col class="max-width-component">
      <div class="d-flex justify-content-center align-items-center">
        <img :src="icon" alt="Tela Cheia">
        <div class="d-flex align-items-center ml-2">
          <span class="custom-font">
            <router-link
              :to="{name: routeName}"
              target="_blank"
              class="custom-link"
            >
              <span
                @click="prepareDataToFullScreen()"
              >Clique aqui</span>
            </router-link>
            e você será redirecionado para uma nova página com o em tela cheia.
          </span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import {
    BRow,
    BCol
  } from "bootstrap-vue";
  import linkIcon from '@/assets/custom-icons/send-link.svg'
  import $dashboardFullScreenState from '@/store/dashboardFullScreenState';

export default {
    components: {
      BRow,
      BCol
    },
    props: {
      routeName: {
        type: String,
        required: true
      },
      dadosRedirecionamento: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        icon: linkIcon
      }
    },

    methods: {
      prepareDataToFullScreen(){
        $dashboardFullScreenState.actions.setObjectDashboard(this.$props.dadosRedirecionamento);
      }
    }
  };
</script>

<style scoped>
.link-full-screen{
  background-color: #efefef;
  height: 87.36px;
  border-radius: 0.428rem;
}
.max-width-component{
  max-width: 460px;
}
.custom-font{
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
}
.custom-link {
  text-decoration: underline;
  color: #2772C0;
}
@media (min-width: 1600px) {
  .custom-font{
    font-size: 14px;
  }
  .max-width-component{
    max-width: 100%;
  }
}
</style>
