<template>
  <section>
    <b-overlay
      v-if="loadingShow"
      :show="loadingShow"
      class="mt-5"
      rounded="lg"
      opacity="0.6"
    />
    <b-overlay
      :show="loadingAtualizationShow"
      rounded="lg"
      opacity="0.6"
    >
      <b-row
        v-if="!loadingShow"
        class="custom-row-gutter"
      >
        <b-col md="6" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_1">
              <titulo-generico
                titulo="Fase"
                :subtitulo="'Período da fase '+ progressoFase(dadosCardFase.selectedFase.inicio, dadosCardFase.selectedFase.termino) +' ('+formatarData(dadosCardFase.selectedFase.inicio)+' - '+formatarData(dadosCardFase.selectedFase.termino)+')'"
              />
              <fase
                :dados="dadosCardFase"
                @atualizaFaseSelecionadaDoDashboard="atualizaFaseSelecionadaDoDashboard"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="6" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_1">
              <titulo-generico
                titulo="Detalhamento por área"
              />
              <detalhamento-por-area
                :key="keyAtualizacaoCards"
                :dados="dadosCardDetalhamentoPorArea"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="7" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_2">
              <titulo-generico
                titulo="Dados gerais"
              />
              <dados-gerais
                :key="keyAtualizacaoCards"
                :dados="dadosCardDadosGerais"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="5" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_2">
              <titulo-generico
                titulo="Respostas por gênero"
              />
              <respostas-por-genero
                :key="keyAtualizacaoCards"
                :dados="dadosCardRespostasPorGenero"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="4" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_3">
              <titulo-generico
                titulo="Estatísticas"
              />
              <estatisticas
                :key="keyAtualizacaoCards"
                :dados="dadosCardEstatisticas"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="8" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_3">
              <titulo-generico
                titulo="Escala de Respostas"
                subtitulo="Atualizado de acordo com o último ciclo"
              />
              <escala-respostas
                :key="keyAtualizacaoCards"
                :dados="dadosCardEscalaRespostas"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="7" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_4">
              <titulo-generico
                titulo="Áreas de maior Dificuldade"
                subtitulo="Atualizado de acordo com o último ciclo"
              />
              <areas-de-maior-dificuldade
                :key="keyAtualizacaoCards"
                :dados="dadosCardAreasDeMaiorDificuldade"
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="5" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_4">
              <titulo-generico
                titulo="Maiores dificuldades no trabalho"
              />
              <maiores-dificuldades-no-trabalho
                :key="keyAtualizacaoCards"
                :dados="dadosCardMaioresDificuldadesNoTrabalho"
              />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_5">
              <titulo-generico
                titulo="NPS"
                tooltipText="Pesquisa que utiliza dados para avaliar a satisfação com a experiência que tiveram no uso da Cora."
              />
              <nps
                :key="keyAtualizacaoCards"
                :dados="dadosCardNps"
              />
            </div>
          </b-card>
        </b-col>
        <b-col
          v-if="typeof this.$props.dadosFullScreen === 'undefined'"
          class="mb-1 custom-col-gutter"
          cols="12"
        >
          <dashboard-redirect-section
            :routeName="rotaDeRedirecionamento"
            :dadosRedirecionamento="dadosRedirecionamento"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { BCard, BRow, BCol, BOverlay } from "bootstrap-vue";
import { modalGenericModel } from '@/libs/sweetalerts';
import TituloGenerico from "@/views/components/custom/dashboard-saude-mental/TituloGenerico.vue";
import DashboardRedirectSection from "@/views/components/custom/dashboard-saude-mental/DashboardRedirectSection.vue";
import DetalhamentoPorArea from "@/views/components/custom/dashboard-saude-mental/dass-21/DetalhamentoPorArea.vue";
import AreasDeMaiorDificuldade from "@/views/components/custom/dashboard-saude-mental/dass-21/AreasDeMaiorDificuldade.vue";
import MaioresDificuldadesNoTrabalho from "@/views/components/custom/dashboard-saude-mental/dass-21/MaioresDificuldadesNoTrabalho.vue";
import Nps from "@/views/components/custom/dashboard-saude-mental/dass-21/NPS.vue";
import Estatisticas from "@/views/components/custom/dashboard-saude-mental/dass-21/Estatisticas.vue";
import RespostasPorGenero from "@/views/components/custom/dashboard-saude-mental/dass-21/RespostasPorGenero.vue";
import EscalaRespostas from "@/views/components/custom/dashboard-saude-mental/dass-21/EscalaDeRespostas.vue";
import Fase from "@/views/components/custom/dashboard-saude-mental/dass-21/Fase.vue";
import DadosGerais from "@/views/components/custom/dashboard-saude-mental/dass-21/DadosGerais.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    TituloGenerico,
    DashboardRedirectSection,
    DetalhamentoPorArea,
    AreasDeMaiorDificuldade,
    MaioresDificuldadesNoTrabalho,
    Nps,
    Estatisticas,
    RespostasPorGenero,
    EscalaRespostas,
    Fase,
    DadosGerais
  },

  props: {
    idEmpresa: {
      type: Number,
      required: true
    },
    rotaDeRedirecionamento: {
      type: String,
      required: true
    },
    dadosFullScreen: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      keyAtualizacaoCards: 0,
      loadingShow: true,
      loadingAtualizationShow: false,
      dadosRedirecionamento: {},
      dadosCardFase: {
        allFases: [],
        selectedFase: null,
      },
      dadosCardEscalaRespostas: [],
      dadosCardAreasDeMaiorDificuldade: [],
      dadosCardMaioresDificuldadesNoTrabalho: [],
      dadosCardNps: {},
      dadosCardEstatisticas: [],
      dadosCardRespostasPorGenero: [],
      dadosCardDadosGerais: [],
      dadosCardDetalhamentoPorArea: [
        {
          descricao: "Total",
          dados: []
        },
        {
          descricao: "setor",
          dados: []
        },
        {
          descricao: "gerencia",
          dados: []
        },
        {
          descricao: "lider",
          dados: []
        }
      ],
      dadosCardDetalhamentoPorAreaTotal: [],
      dadosCardDetalhamentoPorAreaSetor: [],
      dadosCardDetalhamentoPorAreaGerencia: [],
      dadosCardDetalhamentoPorAreaLider: []
    };
  },

  async mounted() {
    await this.buscaFasesDisponiveis()
  },

  methods: {
    async buscaFasesDisponiveis() {
      let rota;
      rota = this.$helpers.preparaRotaComParametros(
          this.$api.buscaFasesDisponiveisDashboardSaudeMentalDass21PorEmpresa,
          "{idEmpresa}",
          this.$props.idEmpresa
        );
      await this.$http
      .get(rota)
      .then(async (res) => {
        if (res.status == 204) {
          modalGenericModel(
            'Resultados não encontrados',
            'A empresa buscada não possui resultados para este questionário',
            require('@/assets/custom-icons/exclamationMark.png'),
            'Entendi'
          ).then(() => {
            this.$emit('retorna-listagem-empresa')
          });
        } else {

          const fasesModificadas = res.data.map(fase => {
            return {
              ...fase,
              descricao: fase.descricao.replace('Ciclo', 'Fase')
            };
          });

          this.dadosCardFase = {
            allFases: fasesModificadas
          }

          this.verificaDashboardFullScreen()
          await this.buscaDados().then(async () => {
            this.preparaArrayFullScreen()
            this.loadingShow = false
          })
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    async buscaDados(){
      await Promise.all([
        this.buscaDadosMultiplosCards(),
        this.buscaDadosCard(this.$api.buscaDadosCardRespostasPorGeneroDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardRespostasPorGenero"),
        this.buscaDadosCard(this.$api.buscaDadosCardDadosGeraisDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardDadosGerais"),
        this.buscaDadosCard(this.$api.buscaDadosCardDetalhamentoPorAreaTotalDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardDetalhamentoPorAreaTotal"),
        this.buscaDadosCard(this.$api.buscaDadosCardDetalhamentoPorAreaSetorDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardDetalhamentoPorAreaSetor"),
        this.buscaDadosCard(this.$api.buscaDadosCardDetalhamentoPorAreaGerenciaDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardDetalhamentoPorAreaGerencia"),
        this.buscaDadosCard(this.$api.buscaDadosCardDetalhamentoPorAreaLiderDashboardSaudeMentalDass21PorFaseEEmpresa, "dadosCardDetalhamentoPorAreaLider")
      ]);
      this.dadosCardDetalhamentoPorArea[0].dados = this.dadosCardDetalhamentoPorAreaTotal
      this.dadosCardDetalhamentoPorArea[1].dados = this.dadosCardDetalhamentoPorAreaSetor
      this.dadosCardDetalhamentoPorArea[2].dados = this.dadosCardDetalhamentoPorAreaGerencia
      this.dadosCardDetalhamentoPorArea[3].dados = this.dadosCardDetalhamentoPorAreaLider
    },

    async buscaDadosMultiplosCards() {
      const params = {
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.$props.idEmpresa
      }
      await this.$http
      .get(this.$api.buscaDadosDashboardSaudeMentalDass21PorFaseEEmpresa, { params })
      .then((res) => {
        this.dadosCardEscalaRespostas = res.data?.escala_resposta ? res.data.escala_resposta : []
        this.dadosCardAreasDeMaiorDificuldade = res.data?.dificuldade ? res.data.dificuldade : []
        this.dadosCardMaioresDificuldadesNoTrabalho = res.data?.dificuldade_trabalho ? res.data.dificuldade_trabalho : []
        this.dadosCardNps = res.data?.indicacao?.length > 0 ? res.data.indicacao[0] : {}
        this.dadosCardEstatisticas = res.data?.estatistica ? res.data.estatistica : []
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    async buscaDadosCard(apiLink, stringPropDados){
      const params = {
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.$props.idEmpresa
      }
      await this.$http
      .get(apiLink, { params })
      .then((res) => {
        if (res.status == 204) {
          this.$set(this, stringPropDados, [])
        } else {
          this.$set(this, stringPropDados, res.data)
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    async atualizaFaseSelecionadaDoDashboard(objectFase){
      this.loadingAtualizationShow = true
      this.dadosCardFase.selectedFase = objectFase
      await this.buscaDados({
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.$props.idEmpresa
      });
      this.keyAtualizacaoCards++
      this.preparaArrayFullScreen()
      this.loadingAtualizationShow = false
    },

    formatarData(data) {
      const objetoData = this.parseDate(data);
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },

    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },

    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return "não iniciada";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return "em andamento";
      } else if (objetoDataFim < objetoDataNow) {
        return "finalizada";
      }
    },
    preparaArrayFullScreen(){
      this.dadosRedirecionamento = {
        idEmpresa: this.$props.idEmpresa,
        nomeQuestionario: "DASS-21",
        idFase: this.dadosCardFase.selectedFase.id_fase
      }
    },
    verificaDashboardFullScreen(){
      if(
        typeof this.$props.dadosFullScreen !== 'undefined'
      ){
        this.dadosCardFase.selectedFase = this.dadosCardFase.allFases.find(
          (item) => item.id_fase == this.$props.dadosFullScreen.idFase
        )
      }else{
        this.dadosCardFase.selectedFase = this.dadosCardFase.allFases[0]
      }
    }
  }
};
</script>

<style scoped>
.card{
  margin-bottom: 15px;
}

.custom-row-gutter{
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.custom-col-gutter {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.altura_linha_1{
  height:323px;
}

.altura_linha_2{
  height:314px;
}

.altura_linha_3{
  height:274px;
}

.altura_linha_4{
  height: 279px;
}

.altura_linha_5{
  height: 116px;
}

@media (max-width: 767px) {
  .altura_linha_1{
    height: 100%;
  }

  .altura_linha_2{
    height: 100%;
  }

  .altura_linha_3{
    height: 100%;
  }

  .altura_linha_4{
    height: 100%;
  }

  .altura_linha_5{
    height: 100%;
  }
}
</style>
<style>
.custom-tooltip .tooltip-inner{
  background-color: #82868B;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 1;
  color: white;
  padding: 5px;
  text-align: left;
}
.custom-tooltip-detalhamento-por-area-grafico .tooltip-inner{
  max-width: 280px;
}
</style>
