<template>
  <div>
    <div
      v-if="Object.keys(dados).length !== 0"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <div
        class="height-custom d-sm-flex justify-content-sm-center align-items-sm-center mt-n1 d-block text-center"
      >
        <div class="custom-margin-right">
          <img
            :src="starIcon"
            alt="NPS"
          >
        </div>
        <div class="custom-margin-right custom-percentage mt-sm-0 mt-1">
          {{ dados.media_indicacao.toString().replace(".", ",") }}
        </div>
        <div class="custom-subtitle mt-sm-0 mt-1">
          MÉDIA DAS NOTAS DA INDICAÇÃO DA PESQUISA
        </div>
      </div>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center mt-n1"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
  import {
    BOverlay
  } from "bootstrap-vue";
import starIcon from "@/assets/custom-icons/mybusinessstar.svg";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";

export default {
  components: {
    CardSemDados,
    BOverlay
  },
  props: {
    dados: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      starIcon
    }
  },
  data() {
    return {
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      }
    };
  },
  mounted() {
    this.loadingShow = false
  }
};
</script>
<style scoped>
.height-custom{
  height: 50px;
}
.custom-margin-right{
  margin-right: 10px;
}
.custom-percentage{
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0px;
  color: #5E5873;
}
.custom-subtitle{
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #B9B9C3;
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 575px) {
  .custom-margin-right{
    margin-right: 0px;
  }
}
</style>
