<template>
  <div>
    <div
      v-if="dados.length > 0"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <b-row
        class="no-gutters height-custom"
      >
        <b-col
          sm="8"
          cols="12"
          class="pr-sm-0 pr-1"
        >
          <div
            class="d-flex justify-content-center align-items-center"
          >
            <v-select
              v-model="dadoSelecionado"
              @input="atualizaDadoSelecionada"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dadosSelect"
              :clearable="false"
              class="select-custom-dados-gerais"
              label="descricao"
            >
              <span slot="no-options">Nenhuma dado encontrado.</span>
            </v-select>
          </div>
          <dados-gerais-grafico
            v-if="dadoSelecionado"
            :key="childKey"
            :dados="graphActualSeries"

          />
        </b-col>
        <b-col
          sm="4"
          cols="12"
          class="d-flex justify-content-center align-items-center"
        >
          <div
            class="spacement-title-subtitle pl-1 mr-1 my-sm-0 my-1 custom-left-border"
          >
            <div
              class="title mb-1 text-sm-left text-left"
            >
              Total: {{ somatorioDoencas }} pessoas
            </div>
            <div
              class="subtitle text-sm-left text-left"
            >
              Cada coluna dos gráficos representa a faixa etária, em ordem crescente. Exemplo:
              21-35 representa a faixa etária de 21-35 anos
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="height-custom d-flex justify-content-center align-items-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import DadosGeraisGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/DadosGeraisGrafico.vue";
import vSelect from "vue-select"

export default {
  components: {
    CardSemDados,
    BOverlay,
    BRow,
    BCol,
    DadosGeraisGrafico,
    vSelect
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loadingShow: true,
      childKey: 0,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      dadoSelecionado: null,
      dadosSelect: [],
      graphGeralDataSeries: [],
      graphActualSeries: [],
      somatorioDoencas: 0
    };
  },
  mounted() {
    this.prepareData()
  },
  methods: {
    prepareData(){
      this.prepareSelectData()
      this.loadingShow = false
    },
    prepareSelectData(){
      for (const element of this.$props.dados) {
        let doencaIndex = this.dadosSelect.indexOf(element['doenca']);
        if(
            doencaIndex === -1
          ){
          this.dadosSelect.push(element['doenca'])
        }
        this.prepareGeralGraphData(element)
      }
      this.graphActualSeries = JSON.parse(JSON.stringify(this.graphGeralDataSeries));
      this.somatorioDoencas = this.retornaTotalGeral()
      this.dadosSelect.unshift('Geral')
      this.dadoSelecionado = this.dadosSelect[0]
    },
    prepareGeralGraphData(arrayElement){
      if(this.graphGeralDataSeries.find(objeto => objeto.name === arrayElement.faixa_etaria) === undefined){
        this.graphGeralDataSeries.push({
          name: arrayElement.faixa_etaria,
          data: [0, 0, 0, 0],
          totalResposta: arrayElement.total_resposta
        })
      }
      this.prepareDoencaGraphData(arrayElement)
    },
    prepareDoencaGraphData(arrayElement){
      const graphObject = this.graphGeralDataSeries.find(objeto => objeto.name === arrayElement.faixa_etaria)
      if(arrayElement.doenca === 'Ansiedade'){
        graphObject.data[0] = arrayElement.total_doenca
      }else if(arrayElement.doenca === 'Depressão'){
        graphObject.data[1] = arrayElement.total_doenca
      }else if(arrayElement.doenca === 'Estresse'){
        graphObject.data[2] = arrayElement.total_doenca
      }else if(arrayElement.doenca === 'Sem Sintomas'){
        graphObject.data[3] = arrayElement.total_doenca
      }
    },
    atualizaDadoSelecionada(doencaString){
      this.graphActualSeries = JSON.parse(JSON.stringify(this.graphGeralDataSeries))
      this.somatorioDoencas = this.retornaTotalGeral()
      if(doencaString === 'Ansiedade'){
        this.prepareDataPerDoenca([1,2,3])
        this.somatorioDoencas = this.retornaTotalPorDoenca(0)
      }else if(doencaString === 'Depressão'){
        this.prepareDataPerDoenca([0,2,3])
        this.somatorioDoencas = this.retornaTotalPorDoenca(1)
      }else if(doencaString === 'Estresse'){
        this.prepareDataPerDoenca([0,1,3])
        this.somatorioDoencas = this.retornaTotalPorDoenca(2)
      }else if(doencaString === 'Sem Sintomas'){
        this.prepareDataPerDoenca([0,1,2])
        this.somatorioDoencas = this.retornaTotalPorDoenca(3)
      }
      this.childKey++
    },
    prepareDataPerDoenca(arrayIndexValue){
      for (const element of this.graphActualSeries) {
        for (const elementIndex of arrayIndexValue) {
          element.data[elementIndex] = 0
        }
      }
    },
    retornaTotalGeral(){
      let sum = 0
      for (const element of this.graphActualSeries) {
        sum += element.totalResposta
      }
      return sum
    },
    retornaTotalPorDoenca(indexArrayValue){
      let sum = 0
      for (const element of this.graphActualSeries) {
        sum = sum + element.data[indexArrayValue]
      }
      return sum
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 220px;
}
.title{
  font-size: 16px;
  color: #5E5873;
  line-height: auto;
  letter-spacing: 0px;
}
.subtitle{
  font-size: 12px;
  color: #B9B9C3;
  line-height: auto;
  letter-spacing: 0px;
}
.spacement-title-subtitle{
  max-width: 175px;
}
.select-custom-dados-gerais{
  min-width: 120px;
}
.select-custom-dados-gerais::v-deep .vs__dropdown-toggle{
  border: 1px solid #7030A0;
  justify-content: space-between;
  align-items: center;
}
.select-custom-dados-gerais::v-deep .vs__selected-options{
  padding: 0;
  flex-basis: inherit;
  flex-grow: initial;
  padding-left: 5px;
}
.select-custom-dados-gerais::v-deep .vs__search{
  margin: 0;
  padding: 0;
  max-height: 0;
  max-width: 0;
}
.select-custom-dados-gerais::v-deep .vs__selected{
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #7030A0 !important;
}
.select-custom-dados-gerais.vs--single.vs--open::v-deep .vs__selected{
  position: relative;
}
.select-custom-dados-gerais::v-deep .feather-chevron-down{
  color: #7030A0;
}
.custom-left-border{
  border-left: 0.93px solid #EBE9F1;
}
@media (min-width: 1600px) {
  .select-custom-dados-gerais::v-deep .vs__selected{
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 575px) {
  .spacement-title-subtitle{
    max-width: 80%;
  }

  .custom-left-border{
    border: none;
  }
}
</style>
