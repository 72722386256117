<template>
    <div
      v-if="dadosParaOComponenteFuncionar.length > 0"
      class="pr-2"
    >
      <div
        v-for="(item, item_id) in dadosParaOComponenteFuncionar"
        :key="item_id"
        class="custom-margin-bottom"
      >
        <span
          class="custom-title-bar"
        >
          {{ item.descricaoDado }}
        </span>
        <b-progress
          :id="'tooltip-target-progress' + item_id"
          class="rounded-0 clicavel"
          height="10px"
          :max="item.max"
        >
          <b-progress-bar
            v-for="(valor, valor_id) in item.escalas"
            :key="valor_id"
            :value="valor.totalEscala"
            :class="valor.classe"
            :id="'tooltip-target-bar' + '-' + item_id + '-' + valor_id"
          >
            <b-tooltip
              :target="'tooltip-target-bar' + '-' + item_id + '-' + valor_id"
              triggers="hover"
              variant="light"
              custom-class="custom-tooltip-detalhamento-por-area-grafico"
            >
              <div
                class="text-left"
              >
                <div
                  v-if="valor.nomeEscala === 0"
                >
                  <div class="tooltip-small-font-size">
                    Sem sintomas em nenhuma escala | <span class="tooltip-small-muted-color">{{ valor.totalEscala }} pessoas</span>
                  </div>
                </div>
                <div
                  v-else
                >
                  <div class="tooltip-normal-font-size">
                    <b>{{ valor.DescricaoEscala }}</b>
                  </div>
                  <div class="tooltip-normal-font-size tooltip-spacement">
                    Total: {{ valor.totalEscala }} pessoas
                  </div>
                  <div class="tooltip-small-font-size">
                    Sintomas mínimos | <span class="tooltip-small-muted-color">{{ valor.sintomasMinimos }} pessoas</span>
                  </div>
                  <div class="tooltip-small-font-size">
                    Sintomas leves | <span class="tooltip-small-muted-color">{{ valor.sintomasLeves }} pessoas</span>
                  </div>
                  <div class="tooltip-small-font-size">
                    Sintomas moderados | <span class="tooltip-small-muted-color">{{ valor.sintomasModerados }} pessoas</span>
                  </div>
                  <div class="tooltip-small-font-size">
                    Sintomas graves | <span class="tooltip-small-muted-color">{{ valor.sintomasGraves }} pessoas</span>
                  </div>
                </div>
              </div>
            </b-tooltip>
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
  </template>
  
  <script>
  import {
    BProgress,
    BProgressBar,
    BCol,
    BTooltip,
    BOverlay,
  } from "bootstrap-vue";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
  
  export default {
    components: {
      BProgress,
      BProgressBar,
      BCol,
      BTooltip,
      VuePerfectScrollbar,
      BOverlay,
      CardSemDados
    },
    props: {
      dados: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        dadosParaOComponenteFuncionar: []
      };
    },
    mounted() {
      this.criarArrayDadosGrafico()
    },
    methods: {
      criarArrayDadosGrafico() {
        for (const element of this.$props.dados.dados) {
          if(this.dadosParaOComponenteFuncionar.find(objeto => objeto.descricaoDado === element[this.$props.dados.descricao]) === undefined){
            this.dadosParaOComponenteFuncionar.push({
              descricaoDado: element[this.$props.dados.descricao],
              max: element.totalizador,
              escalas: [
                {
                  nomeEscala: 0,
                  DescricaoEscala: 'Sem sintomas',
                  totalEscala: 0,
                  classe: "cor-sem-sintomas",
                  semSintomas: 0,
                  sintomasMinimos: 0,
                  sintomasLeves: 0,
                  sintomasModerados: 0,
                  sintomasGraves: 0,
                },
                {
                  nomeEscala: 1,
                  DescricaoEscala: 'Sintomas mínimos',
                  totalEscala: 0,
                  classe: "cor-sintomas-minimos",
                  semSintomas: 0,
                  sintomasMinimos: 0,
                  sintomasLeves: 0,
                  sintomasModerados: 0,
                  sintomasGraves: 0,
                },
                {
                  nomeEscala: 2,
                  DescricaoEscala: 'Sintomas leves',
                  totalEscala: 0,
                  classe: "cor-sintomas-leves",
                  semSintomas: 0,
                  sintomasMinimos: 0,
                  sintomasLeves: 0,
                  sintomasModerados: 0,
                  sintomasGraves: 0,
                },
                {
                  nomeEscala: 3,
                  DescricaoEscala: 'Sintomas moderados',
                  totalEscala: 0,
                  classe: "cor-sintomas-moderados",
                  semSintomas: 0,
                  sintomasMinimos: 0,
                  sintomasLeves: 0,
                  sintomasModerados: 0,
                  sintomasGraves: 0,
                },
                {
                  nomeEscala: 4,
                  DescricaoEscala: 'Sintomas graves',
                  totalEscala: 0,
                  classe: "cor-sintomas-graves",
                  semSintomas: 0,
                  sintomasMinimos: 0,
                  sintomasLeves: 0,
                  sintomasModerados: 0,
                  sintomasGraves: 0,
                }
              ]
            })
          }
        }
        this.preencheDadosDoencas()
      },
      preencheDadosDoencas(){
        for (const element of this.$props.dados.dados) {
          const objetoTipoDado = this.dadosParaOComponenteFuncionar.find(objeto => objeto.descricaoDado === element[this.$props.dados.descricao]);
     
          for (const elementDoenca of objetoTipoDado.escalas) {
            if(element.escala === elementDoenca.nomeEscala){
              elementDoenca.totalEscala = element.totalizador;
              if(element.escala === 0){
                elementDoenca.semSintomas = element.totalizador
              }else if(element.escala === 1){
                elementDoenca.sintomasMinimos = element.totalizador
              }else if(element.escala === 2){
                elementDoenca.sintomasLeves = element.totalizador
              }else if(element.escala === 3){
                elementDoenca.sintomasModerados = element.totalizador
              }else if(element.escala === 4){
                elementDoenca.sintomasGraves = element.totalizador
              }
            }
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  .custom-title-bar{
    font-size: 14px;
    font-weight: 500;
  }
  .custom-margin-bottom{
    margin-bottom: 8px;
  }
  .lista{
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size:13px;
    white-space:nowrap;
    width:100%;
  }
  .alturaLinha{
    height:20px;
  }
  .cor-sintomas-minimos{
    background-color: #2772C0;
  }
  .cor-sintomas-leves{
    background-color: #002060;
  }
  .cor-sintomas-moderados{
    background-color: #7030A0;
  }
  .cor-sintomas-graves{
    background-color: #AA228C;
  }
  .cor-sem-sintomas{
    background-color: #36BA00;
  }
  .tooltip-normal-font-size{
    font-size: 10px;
    letter-spacing: 0px;
    line-height: 12px;
  }
  .tooltip-small-font-size{
    color: #5E5873;
    font-size: 9px;
  }
  .tooltip-small-muted-color{
    color: #B9B9C3;
  }
  .tooltip-spacement{
    margin-bottom: 6px;
  }
  </style>
  