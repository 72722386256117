<template>
  <div
    v-if="dadosParaOComponenteFuncionar.length > 0"
    class="pr-2"
  >
    <div
      v-for="(item, item_id) in dadosParaOComponenteFuncionar"
      :key="item_id"
      class="custom-margin-bottom"
    >
      <span
        class="custom-title-bar"
      >
        {{ item.descricaoDado }}
      </span>
      <b-progress
        :id="'tooltip-target-progress' + item_id"
        class="rounded-0 clicavel"
        height="10px"
        :max="item.max"
      >
        <b-progress-bar
          v-for="(valor, valor_id) in item.doencas"
          :key="valor_id"
          :value="valor.totalDoenca"
          :class="valor.classe"
          :id="'tooltip-target-bar' + '-' + item_id + '-' + valor_id"
        >
          <b-tooltip
            :target="'tooltip-target-bar' + '-' + item_id + '-' + valor_id"
            triggers="hover"
            variant="light"
            custom-class="custom-tooltip-detalhamento-por-area-grafico"
          >
            <div
              class="text-left"
            >
              <div
                v-if="valor.nomeDoenca === 'Sem Sintomas'"
              >
                <div class="tooltip-small-font-size">
                  Sem sintomas em nenhuma doença | <span class="tooltip-small-muted-color">{{ valor.totalDoenca }} pessoas</span>
                </div>
              </div>
              <div
                v-else
              >
                <div class="tooltip-normal-font-size">
                  <b>{{ valor.nomeDoenca }}</b>
                </div>
                <div class="tooltip-normal-font-size tooltip-spacement">
                  Total: {{ valor.totalDoenca }} pessoas
                </div>
                <div class="tooltip-small-font-size">
                  Sintomas leves | <span class="tooltip-small-muted-color">{{ valor.sintomasLeves }} pessoas</span>
                </div>
                <div class="tooltip-small-font-size">
                  Sintomas moderados | <span class="tooltip-small-muted-color">{{ valor.sintomasModerados }} pessoas</span>
                </div>
                <div class="tooltip-small-font-size">
                  Sintomas graves | <span class="tooltip-small-muted-color">{{ valor.sintomasGraves }} pessoas</span>
                </div>
                <div class="tooltip-small-font-size">
                  Sintomas muito graves | <span class="tooltip-small-muted-color">{{ valor.sintomasMuitoGraves }} pessoas</span>
                </div>
              </div>
            </div>
          </b-tooltip>
        </b-progress-bar>
      </b-progress>
    </div>
  </div>
</template>

<script>
import {
  BProgress,
  BProgressBar,
  BCol,
  BTooltip,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";

export default {
  components: {
    BProgress,
    BProgressBar,
    BCol,
    BTooltip,
    VuePerfectScrollbar,
    BOverlay,
    CardSemDados
  },
  props: {
    dados: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dadosParaOComponenteFuncionar: []
    };
  },
  mounted() {
    this.criarArrayDadosGrafico()
  },
  methods: {
    criarArrayDadosGrafico() {
      for (const element of this.$props.dados.dados) {
        if(this.dadosParaOComponenteFuncionar.find(objeto => objeto.descricaoDado === element[this.$props.dados.descricao]) === undefined){
          this.dadosParaOComponenteFuncionar.push({
            descricaoDado: element[this.$props.dados.descricao],
            max: element.total_resposta,
            doencas: [
              {
                nomeDoenca: "Ansiedade",
                totalDoenca: 0,
                classe: "cor-ansiedade",
                sintomasLeves: 0,
                sintomasModerados: 0,
                sintomasGraves: 0,
                sintomasMuitoGraves: 0
              },
              {
                nomeDoenca: "Depressão",
                totalDoenca: 0,
                classe: "cor-depressao",
                sintomasLeves: 0,
                sintomasModerados: 0,
                sintomasGraves: 0,
                sintomasMuitoGraves: 0
              },
              {
                nomeDoenca: "Estresse",
                totalDoenca: 0,
                classe: "cor-estresse",
                sintomasLeves: 0,
                sintomasModerados: 0,
                sintomasGraves: 0,
                sintomasMuitoGraves: 0
              },
              {
                nomeDoenca: "Sem Sintomas",
                totalDoenca: 0,
                classe: "cor-sem-sintomas",
                sintomasLeves: 0,
                sintomasModerados: 0,
                sintomasGraves: 0,
                sintomasMuitoGraves: 0
              }
            ]
          })
        }
      }
      this.preencheDadosDoencas()
    },
    preencheDadosDoencas(){
      for (const element of this.$props.dados.dados) {
        const objetoTipoDado = this.dadosParaOComponenteFuncionar.find(objeto => objeto.descricaoDado === element[this.$props.dados.descricao]);
        for (const elementDoenca of objetoTipoDado.doencas) {
          if(element.doenca === elementDoenca.nomeDoenca){
            elementDoenca.totalDoenca = element.total_doenca;
            if(element.descricao_escala === "Sintomas Leves"){
              elementDoenca.sintomasLeves = element.total_escala
            }else if(element.descricao_escala === "Sintomas Moderados"){
              elementDoenca.sintomasModerados = element.total_escala
            }else if(element.descricao_escala === "Sintomas Graves"){
              elementDoenca.sintomasGraves = element.total_escala
            }else if(element.descricao_escala === "Sintomas Muito Graves"){
              elementDoenca.sintomasMuitoGraves = element.total_escala
            }
          }
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-title-bar{
  font-size: 14px;
  font-weight: 500;
}
.custom-margin-bottom{
  margin-bottom: 8px;
}
.lista{
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size:13px;
  white-space:nowrap;
  width:100%;
}
.alturaLinha{
  height:20px;
}
.cor-ansiedade{
  background-color: #2772C0;
}
.cor-depressao{
  background-color: #002060;
}
.cor-estresse{
  background-color: #7030A0;
}
.cor-sem-sintomas{
  background-color: #36BA00;
}
.tooltip-normal-font-size{
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 12px;
}
.tooltip-small-font-size{
  color: #5E5873;
  font-size: 9px;
}
.tooltip-small-muted-color{
  color: #B9B9C3;
}
.tooltip-spacement{
  margin-bottom: 6px;
}
</style>
