<template>
  <canvas
    ref="myChart"
  ></canvas>
</template>

<script>
import Chart from 'chart.js';
Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.labels.usePointStyle = true;

export default {
  props: {
    chartData: {
      type: Array,
      required: true
    },
    sumRespostas: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      myChart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (this.myChart) this.myChart.destroy();
      const ctx = this.$refs.myChart.getContext('2d');
      const config = this.getChartConfig();
      this.myChart = new Chart(ctx, config);
      const sumRespostas = this.$props.sumRespostas;
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (chart.config.type === 'doughnut') {
            let width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;
            ctx.clearRect(0, 0, width, height);

            ctx.font = " 27px Montserrat";
            ctx.fillStyle = "#5E5873";

            let text = sumRespostas,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 1.9;
            ctx.fillText(text, textX, textY);
            ctx.save();

            width = chart.chart.width
            height = chart.chart.height
            ctx = chart.chart.ctx

            ctx.font = "10px Montserrat";
            ctx.fillStyle = "#B9B9C3";

            text = "Respostas"
            textX = Math.round((width - ctx.measureText(text).width) / 2)
            textY = height / 1.6

            ctx.fillText(text, textX, textY);
            ctx.save();
          }
        }
      });
    },
    getChartConfig() {
      const data = {
          labels: ['Sem sintomas', 'Sintomas Leves', 'Sintomas Moderados', 'Sintomas Graves', 'Sintomas Muito Graves'],
          datasets: [
            {
              label: 'dataset do gráfico de escala de respostas',
              data: this.$props.chartData,
              backgroundColor: ['#36BA00', '#2772C0', '#002060', '#7030A0', '#AA228C'],
              borderWidth: 3
            },
          ]
      };
      const config = {
        type: 'doughnut',
        data: data,
        options: {
          cutoutPercentage: 65,
          responsive: true,
          maintainAspectRatio: false,
          plugins:{
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
              }
            },
            title: {
              display: false,
              text: 'Gráfico de escala de respostas',
            },
          },
          tooltips: {
            usePointStyle: true,
            pointStyle: 'circle',
            backgroundColor: '#FFF',
            titleFontColor: '#5E5873',
            bodyFontColor: '#5E5873',
            borderColor: "#2772C0",
            borderWidth: 1,
            titleFontFamily: "Montserrat",
            titleFontSize: 11,
            bodyFontFamily: "Montserrat",
            bodyFontSize: 10,
            cornerRadius: 6,
          },
        },
        plugin: [
          {
            id: 'my-doughnut-text-plugin',
            afterDraw: function (chart) {
              let theCenterText = "90%";
              const canvasBounds = canvas.getBoundingClientRect();
              const fontSz = Math.floor(canvasBounds.height * 0.10);
              chart.ctx.textBaseline = 'middle';
              chart.ctx.textAlign = 'center';
              chart.ctx.font = fontSz + 'px Arial';
              chart.ctx.fillText(theCenterText, canvasBounds.width / 2, canvasBounds.height * 0.70)
            }
          }
        ]
      };

      return config;
    }
  },
};
</script>
