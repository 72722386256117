<template>
  <div v-if="dados && dados.length > 0">
    <b-overlay
      v-if="loadingShow"
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    />
    <div class="ordenacao">
      <span class="ordenar-por-font-size">Ordenar por: </span>
      <div>
        <b-button
          class="alfaBtn"
          variant="outline-secondary"
          hover
          title="Ordem alfabética"
          @click="ordenarAlfa(!tipoOrdemAlfa)"
        >
          <span v-if="tipoOrdemAlfa">A > Z</span>
          <span v-else>A &lt; Z</span>
        </b-button>
        <b-button
          class="percentBtn"
          variant="outline-secondary"
          hover
          title="Ordenar por porcentagem"
          @click="ordenarPorcentAsc()"
        >
          <feather-icon icon="ChevronsDownIcon" />
        </b-button>
        <b-button
          class="percentBtn"
          variant="outline-secondary"
          hover
          title="Ordenar por porcentagem"
          @click="ordenarPorcentDesc()"
        >
          <feather-icon icon="ChevronsUpIcon" />
        </b-button>
      </div>
    </div>
    <div class="p-2">
      <vue-perfect-scrollbar class="scroll-area" :settings="settings">
        <div class="custom-scroll-area">
          <div
            v-for="(funcionario, id) in dadosParaOComponenteFuncionar"
            :key="id"
            class="
              employee-task
              d-flex
              justify-content-between
              align-items-center
              pr-2
              pb-2
            "
            style="min-width:100%;"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  class="rounded-circle tamanho-avatar"
                  size="40"
                  :src="funcionario.avatar"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0 tamanho-fonte-nome-funcionario">
                  {{ funcionario.nome }}
                </h6>
                <small class="tamanho-fonte-setor-funcionario">{{ funcionario.setor }}</small>
              </b-media-body>
            </b-media>

            <div class="d-flex align-items-center">
              <small class="text-muted mr-50"
                >{{
                  porcentagemPorDoisSemNulo(
                    funcionario.respostas,
                    funcionario.liderados
                  )
                }}%</small
              >
              <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="chartData[id].options ? chartData[id].options : {}"
                :series="chartData[id].series ? chartData[id].series : {}"
              />
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
  <div
    v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
  </div>
</template>

<script>
import {
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
  BButton,
  BRow,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/phq-9/CardSemDadosRespostasEquipe.vue";

const $trackBgColor = "#e9ecef";
const $fillColor = "#7030a0";
export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    VueApexCharts,
    VuePerfectScrollbar,
    BOverlay,
    CardSemDados,
    BButton,
    BRow,
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      validarCardSemDados: {
        verificadorVazio: false,
        tipagemErro: 0,
      },
      loadingShow: true,
      tipoOrdemAlfa: true,
      dadosParaOComponenteFuncionar: [],
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.3,
        wheelPropagation: false,
      },
      chartData: [],
      chart: {
        series: [0],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$fillColor],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  mounted() {
    this.buscaDadosCardTaxaRespostaEquipe();
    this.loadingShow = false;
  },
  methods: {
    buscaDadosCardTaxaRespostaEquipe() {

        this.dadosParaOComponenteFuncionar = this.$props.dados;
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);

        this.ordenarAlfa(true);
    },
    adicionarCampoPoercent() {
      this.dadosParaOComponenteFuncionar.map((item, index) => {
        this.dadosParaOComponenteFuncionar[index].percent =
          this.porcentagemPorDoisSemNulo(item.respostas, item.liderados);
      });
    },

    ordenarAlfa(asc) {
      this.tipoOrdemAlfa = asc;
      this.adicionarCampoPoercent();
      Promise.all(
        this.dadosParaOComponenteFuncionar.sort((a, b) => {
          if (asc) {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
          } else {
            if (a.nome > b.nome) return -1;
            if (a.nome < b.nome) return 1;
          }
          return 0;
        })
      ).then(() => {
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
      });
    },
    ordenarPorcentDesc() {
      this.adicionarCampoPoercent();
      Promise.all(
        this.dadosParaOComponenteFuncionar.sort((a, b) => {
          if (parseInt(a.percent) > parseInt(b.percent)) return -1;
          if (parseInt(a.percent) < parseInt(b.percent)) return 1;
          return 0;
        })
      ).then(() => {
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
      });
    },
    ordenarPorcentAsc() {
      this.adicionarCampoPoercent();
      Promise.all(
        this.dadosParaOComponenteFuncionar.sort((a, b) => {
          if (parseInt(a.percent) < parseInt(b.percent)) return -1;
          if (parseInt(a.percent) > parseInt(b.percent)) return 1;
          return 0;
        })
      ).then(() => {
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
      });
    },
    criacaoPreenchimentoCharts(dadosCharts) {
      this.chartData = [];
      for (const element of dadosCharts) {
        const chartClone = JSON.parse(JSON.stringify(this.chart));
        const porcentagem = this.porcentagemPorDoisSemNulo(
          element.respostas,
          element.liderados
        );
        const codigoCor = this.condicaoCor(porcentagem);
        chartClone.options.colors[0] = codigoCor;
        chartClone.series[0] = porcentagem;
        this.chartData.push(chartClone);
      }
    },
    condicaoCor(porcentagem) {
      if (porcentagem == 0 || porcentagem == "") {
        return "#e9ecef";
      } else if (porcentagem > 0 && porcentagem <= 19) {
        return "#AA228C";
      } else if (porcentagem >= 20 && porcentagem <= 44) {
        return "#002060";
      } else if (porcentagem >= 45 && porcentagem <= 74) {
        return "#2772C0";
      } else if (porcentagem >= 75 && porcentagem <= 99) {
        return "#7030A0";
      } else if (porcentagem == 100) {
        return "#36BA00";
      }
    },
    porcentagemPorDoisSemNulo(valorX, valorY) {
      if (valorY != 0) {
        return ([valorX / valorY] * 100).toFixed(0);
      } else {
        return '0';
      }
    },
  },
};
</script>
<style scoped>
.height-custom{
  height: 240px;
}
.ordenacao{
  display: flex;
  margin-right: 10px;
  justify-content: end;
  align-items: center;
  margin-top: -10px;
}
.alfaBtn{
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 0px;
}
.percentBtn{
  padding: 5px;
  border-radius: 0px;
}
.scroll-area{
  height: 268px;
}

.custom-scroll-area{
  height: 100%;
  min-width: 200px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 1327px){
  .alfaBtn{
    padding: 3px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
  }
  .percentBtn{
    padding: 2px;
  }

  .ordenar-por-font-size{
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1400px){
  .scroll-area{
    height: 225px;
  }
}



@media (min-width: 768px) and (max-width: 991px){
    .tamanho-fonte-nome-funcionario{
      font-size: 12px;
    }
    .tamanho-fonte-setor-funcionario{
      font-size: 10px;
    }
    .tamanho-avatar{
      width: 30px !important;
      height: 30px !important;
    }
  }
</style>
