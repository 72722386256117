<template>
  <b-card title="Fases">
    <b-card-text class="margem-subtitulo_responsivo">Atualizado de acordo com o último ciclo</b-card-text>
    <vue-perfect-scrollbar class="scroll-area" :settings="settings">
      <div class=" ">
        <div class="container-fluid">
          <b-row class="justify-content-center mt-3">
            <b-col lg="10" md="10" sm="12">
              <v-select
                :clearable="false"
                :options="opcaoSelect"
                v-model="selecionado"
              ></v-select>
              <app-timeline class="mt-3 justify-content-center d-flex">
                <app-timeline-item class="clicavel">
                  <div>
                    <h6>Fase {{ faseSelecao.id_fase }}</h6> 
                  </div>
                  <p class="text-muted mt-1 texto-participantes">{{ faseSelecao.participantes }} participantes </p>
                  <p class="text-muted mt-1 texto-participantes">{{ faseSelecao.respostas }} respostas</p>
                </app-timeline-item>
              </app-timeline>
            </b-col>
          </b-row>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";


export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardText,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
    vSelect
  },
  props: {
    Fases: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      faseSelecao: {},
      selecionado: `Fase ${this.FaseSelecionada.id_fase}`,
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.3,
        wheelPropagation: false,
      }
    }
  },
  beforeMount(){
    this.verficaSelecionado()
    this.faseSelecao = this.FaseSelecionada
  },
  methods: {
    formatarDataMesAno(data){

      const nomeMes = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
      const objetoData = this.parseDate(data);
      return nomeMes[objetoData.getMonth()] + " " + objetoData.getFullYear();
    },
    parseDate(input) {
      if (input != undefined) {
        let parts = input.match(/(\d+)/g);
        return new Date(parts[0], parts[1]-1, parts[2]);
      } else {
        return null;
      }
    },
    atualizarCards(cicloSelecionado){
      this.$emit("AtualizarCicloSelecionado", cicloSelecionado);
    },

    verficaSelecionado(){
      let teste = this.selecionado
      teste = teste.split("Fase ")
      this.Fases.forEach((fase) => {
        if(fase.id_fase == teste[1]){
          this.faseSelecao = fase
        }
      })
    }
  },

  computed: {
    opcaoSelect() {
      return this.Fases.map((fase) => {
      return `Fase ${fase.id_fase}`
      })
    },
  },

  watch: {
    selecionado(){
      this.verficaSelecionado()
      this.atualizarCards(this.faseSelecao)
    }
  }

}
</script>
<style scoped>
.timeline-item-point {
    background-color: #2772C0 !important;
}
.margem-subtitulo_responsivo{
  margin: -14px 0 0 auto;
}
.timeline-item{
  padding-left: 1.5rem !important;
  left: none !important;
}
.timeline-item:last-of-type:after {
  display: none !important;
}
.scroll-area{
  height: 275px;
}

.texto-participantes{
  min-width: 100px;
}

.custom-scroll-area{
  height: 100%;
  min-width: 200px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 1601px) and (max-width: 1618px) {
  .scroll-area{
    height: 292px;
  }
}

@media (min-width: 1557px) and (max-width: 1600px) {
  .scroll-area{
    height: 324px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .scroll-area{
    height: 310px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {

  .scroll-area{
    height: 263px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .scroll-area{
    height: 270px;
  }
}

@media (min-width: 866px) and (max-width: 991px) {

  /* .scroll-area{
    height: 519px;
  } */
  .scroll-area{
      height: 210px;
  }
}

@media (min-width: 768px) and (max-width: 865px) {

  /* .scroll-area{
    height: 499px;
  } */
  .scroll-area{
    height: 210px;
  }
}
</style>
