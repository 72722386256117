<template>
  <div>
    <div
      v-if="dados.length > 0"
    >
      <b-overlay
        v-if="loadingShow" 
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <div>
        <b-row
          class="no-gutters px-2 height-custom"
        >
          <b-col
            sm="4"
            cols="12"
            class="d-flex justify-content-sm-end align-items-sm-center mb-sm-0 justify-content-center mb-1"
          >
            <div
              class="minimal-graph-size"
            >
              <escala-de-respostas-grafico
                v-if="chartDataSelecionada.length > 0"
                :key="childKey"
                :chartData="chartDataSelecionada"
                :sumRespostas="sumGraphSelecionada"
              />
            </div>
          </b-col>
          <b-col
            sm="4"
            cols="12"
            class="text-center my-sm-auto mb-2 px-1"
          >
            <div
              class="title-pick-sickness mb-1"
            >
              Escolha uma doença
            </div>
            <div
              class="d-flex justify-content-center align-items-center"
            >
              <v-select
                v-model="doencaSelecionada"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="doencasSelect"
                :clearable="false"
                class="select-custom-escala-de-respostas"
                label="descricao"
                @input="atualizaDoencaSelecionada"
              >
                <span slot="no-options">Nenhuma doença encontrada.</span>
              </v-select>
            </div>
          </b-col>
          <b-col
            sm="4"
            cols="12"
            class="d-flex justify-content-sm-start justify-content-center align-items-center"
          >
            <div
              class="grouping-escalas"
            >
              <div class="align-by-line">
                <div
                  class="spacement-bullet"
                >
                  <div
                    class="bullet-escala bullet-color-escala-0"
                  ></div>
                </div>
                <div
                  class="spacement-label"
                >
                  <div class="item-escala">Escala 0</div>
                  <div class="vertical-bar"></div>
                  <div class="item-escala-muted-text">sem sintomas</div>
                </div>
              </div>
              <div class="align-by-line">
                <div
                  class="spacement-bullet"
                >
                  <div
                    class="bullet-escala bullet-color-escala-1"
                  ></div>
                </div>
                <div
                  class="spacement-label"
                >
                  <div class="item-escala">Escala 1</div>
                  <div class="vertical-bar"></div>
                  <div class="item-escala-muted-text">sintomas leves</div>
                </div>
              </div>
              <div class="align-by-line">
                <div
                  class="spacement-bullet"
                >
                  <div
                    class="bullet-escala bullet-color-escala-2"
                  ></div>
                </div>
                <div
                  class="spacement-label"
                >
                  <div class="item-escala">Escala 2</div>
                  <div class="vertical-bar"></div>
                  <div class="item-escala-muted-text">sintomas moderados</div>
                </div>
              </div>
              <div class="align-by-line">
                <div
                  class="spacement-bullet"
                >
                  <div
                    class="bullet-escala bullet-color-escala-3"
                  ></div>
                </div>
                <div
                  class="spacement-label"
                >
                  <div class="item-escala">Escala 3</div>
                  <div class="vertical-bar"></div>
                  <div class="item-escala-muted-text">sintomas graves</div>
                </div>
              </div>
              <div class="align-by-line">
                <div
                  class="spacement-bullet"
                >
                  <div
                    class="bullet-escala bullet-color-escala-4"
                  ></div>
                </div>
                <div
                  class="spacement-label"
                >
                  <div class="item-escala">Escala 4</div>
                  <div class="vertical-bar"></div>
                  <div class="item-escala-muted-text">sintomas muito graves</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import EscalaDeRespostasGrafico from "@/views/components/custom/dashboard-saude-mental/dass-21/EscalaDeRespostasGrafico.vue";
import vSelect from "vue-select"

export default {
  components: {
    CardSemDados,
    BOverlay,
    EscalaDeRespostasGrafico,
    BRow,
    BCol,
    vSelect
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      childKey: 0,
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      doencaSelecionada: null,
      doencasSelect: [],
      chartData: [],
      chartDataSelecionada: [],
      sumGraph: [],
      sumGraphSelecionada: [],
    };
  },
  mounted() {
    this.prepareData()
    this.doencaSelecionada = this.doencasSelect[0]
    this.chartDataSelecionada = this.chartData[0]
    this.sumGraphSelecionada = this.sumGraph[0]
    this.loadingShow = false
  },
  methods: {
    prepareData(){
      for (const element of this.$props.dados) {
        let doencaIndex = this.doencasSelect.indexOf(element['doenca']);
        if(
            doencaIndex === -1
          ){
          this.doencasSelect.push(element['doenca'])
        }
        doencaIndex = doencaIndex === -1 ? this.doencasSelect.length - 1 : doencaIndex
        this.prepareSeparationPerEscala(element, doencaIndex)
      }
      this.prepareSumDoenca()
    },
    prepareSeparationPerEscala(arrayElement, arrayDoencaIndex){
      if(this.chartData[arrayDoencaIndex] === undefined){
        this.chartData[arrayDoencaIndex] = []
      }
      this.chartData[arrayDoencaIndex][arrayElement['escala']] = arrayElement['total_doenca']
    },
    prepareSumDoenca(){
      for (let i = 0; i < this.chartData.length; i++) {
        this.sumGraph[i] = this.chartData[i].reduce((acumulador, valorAtual) => {
            return acumulador + valorAtual;
        }, 0);
      }
    },
    atualizaDoencaSelecionada(doencaString){
      const indexSelecionado = this.doencasSelect.indexOf(doencaString)
      this.chartDataSelecionada = this.chartData[indexSelecionado]
      this.sumGraphSelecionada = this.sumGraph[indexSelecionado]
      this.childKey++
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 190px;
}
.select-custom-escala-de-respostas{
  min-width: 100px;
}
.select-custom-escala-de-respostas::v-deep .vs__dropdown-toggle{
  border: 1px solid #7030A0;
  justify-content: space-between;
  align-items: center;
}
.select-custom-escala-de-respostas::v-deep .vs__selected-options{
  padding: 0;
  flex-basis: inherit;
  flex-grow: initial;
  padding-left: 5px;
}
.select-custom-escala-de-respostas::v-deep .vs__search{
  margin: 0;
  padding: 0;
  max-height: 0;
  max-width: 0;
}
.select-custom-escala-de-respostas::v-deep .vs__selected{
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #7030A0 !important;
}
.select-custom-escala-de-respostas.vs--single.vs--open::v-deep .vs__selected{
  position: relative;
}
.select-custom-escala-de-respostas::v-deep .feather-chevron-down{
  color: #7030A0;
}
.title-pick-sickness{
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #5E5873;
}
.minimal-graph-size{
  height: 100%;
  width: 100%;
  max-height: 190px;
  max-width: 190px;
}
.bullet-color-escala-0{
  background-color: #36BA00;
}
.bullet-color-escala-1{
  background-color: #2772C0;
}
.bullet-color-escala-2{
  background-color: #002060;
}
.bullet-color-escala-3{
  background-color: #7030A0;
}
.bullet-color-escala-4{
  background-color: #AA228C;
}
.grouping-escalas{
  font-size: 10px;
  font-weight: 500;
}
.align-by-line{
  display: flex;
  align-items: center;
  height: 15px;
  margin-bottom: 5px;
}
.spacement-bullet{
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.bullet-escala{
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.spacement-label{
  height: 100%;
  display: flex;
  align-items: center;
}
.item-escala{
  min-width: 45px;
}
.vertical-bar{
  background-color: black;
  height: 90%;
  width: 1px;
  margin-left: 4px;
  margin-right: 5px;
}
.item-escala-muted-text{
  font-size: 9px;
  color: #B9B9C3;
  font-weight: 500;
  line-height: 9px;
}
@media (min-width: 1600px) {
  .select-custom-escala-de-respostas::v-deep .vs__selected{
    font-size: 13px;
  }
  .item-escala{
    min-width: 55px;
  }
  .grouping-escalas{
    font-size: 12px;
  }
  .item-escala-muted-text{
    font-size: 10px;
  }
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1.5rem;
  }
}
</style>
