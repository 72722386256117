<template>
  <div
    class="p-2"
    style="background-color: #F8F8F8;"
  >
    <div
      class="p-2"
      style="background-color: #FFFFFF;"
    >
      <dashboardEmpresa
        :dadosFullScreen="dadosFullScreen"
      />
    </div>
  </div>
</template>

<script>
import DashboardEmpresa from "@/views/pages/dashboard/saude-mental/DashboardEmpresa.vue";
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import $dashboardFullScreenState from '@/store/dashboardFullScreenState';

export default {
  components: {
    DashboardEmpresa
  },

  data() {
    return {
      dadosFullScreen: {}
    };
  },

  async mounted() {
    await this.verifyObjectDashboardVuex()
  },

  methods: {
    async verifyObjectDashboardVuex() {
      if(
        $dashboardFullScreenState.getters.getObjectDashboard() === null && typeof $dashboardFullScreenState.getters.getObjectDashboard() === 'undefined'
      ) {
        await modalGenericFillableErrorWithReload(
          'Não foi possível acessar o painel selecionado.'
        )
      }
      this.dadosFullScreen = $dashboardFullScreenState.getters.getObjectDashboard()
    },
  }
}
</script>
