<template>
  <div>
    <div class="spacement-correction">
      <div
        class="title"
        :id="customRef"
        :ref="customRef"
        v-b-tooltip.bottom="{
          customClass: 'custom-tooltip',
          title: useTooltip ? longText : ''
        }"
      >
        {{ longText }}
      </div>
    </div>
    <div>
      <b-progress
        max="100"
        class="progress-bar-info w-100"
      >
        <b-progress-bar
          :value="barPercentage"
          :style="'background-color: '+barColor"
        />
      </b-progress>
    </div>
  </div>
</template>

<script>
  import {
    BProgress,
    BProgressBar,
    VBTooltip
  } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BProgressBar
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    barTitle: {
      type: String,
      required: true
    },
    barAbsoluteNumber: {
      type: Number,
      required: true
    },
    barPercentage: {
      type: String,
      required: true
    },
    barColor: {
      type: String,
      required: true
    },
    refKey: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      longText: this.$props.barTitle + ': ' + this.$props.barAbsoluteNumber + ' pessoas',
      customRef: 'ref-'+this.$props.refKey+'-'+this.$props.barTitle,
      useTooltip: false
    }
  },
  mounted(){
    window.addEventListener('resize', () => {
      this.verifyTooltipUse()
    });
    this.verifyTooltipUse()
  },
  methods: {
    verifyTooltipUse(){
      if(
        this.$refs[this.customRef]?.offsetWidth &&
        this.$refs[this.customRef].scrollWidth &&
        this.$refs[this.customRef].offsetWidth < this.$refs[this.customRef].scrollWidth
      ){
        this.useTooltip = true
      }else{
        this.useTooltip = false
      }
    }
  }
};
</script>
<style scoped>
.spacement-correction{
  font-size: 12px;
  letter-spacing: 0px;
}
.title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.progress-bar-info{
  margin-top: 6px;
  height: 6px;
}
</style>
