<template>
  <div>
    <div
      v-if="dados.length > 0"
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <div
        class="d-flex justify-content-center align-content-center"
      >
        <v-select
          v-model="estatisticaSelecionada"
          @input="alteraEstatistica()"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="estatisticasSelect"
          :clearable="false"
          class="select-custom-estatisticas"
          label="doenca"
          item-text="doenca"
          item-value="doenca"
        >
          <span slot="no-options">Nenhuma estatística encontrada.</span>
        </v-select>
      </div>
      <b-row
        class="no-gutters pt-md-3 pb-md-0 py-2 px-1"
      >
        <b-col
          sm="6"
          cols="12"
        >
          <div
            class="responsive-height-broke-text"
          >
            <div
              class="icon-title"
            >
              MINHA EMPRESA
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <img
                :src="cubeIcon"
                alt="Ícone minha empresa"
                width="48"
                height="48"
              />
            </div>
            <div class="custom-percentage">
              {{ estatisticasPercentual }}%
            </div>
          </div>
        </b-col>
        <b-col
          sm="6"
          cols="12"
          class="pt-sm-0 pt-2"
        >
          <div
            class="responsive-height-broke-text"
          >
            <div
              class="icon-title"
            >
              PESQUISA VITTUDE
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <img
                :src="brazilIcon"
                alt="Ícone pesquisa Vittude"
                width="48"
                height="48"
              />
            </div>
            <div
              class="custom-percentage"
              v-b-tooltip.bottom="{
                customClass: 'custom-tooltip',
                title: 'Pesquisa realizada por plataforma de terapia online, com aplicação do questionário DASS-21.'
              }"
            >
              {{ vittudePercentual }}%
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  VBTooltip
} from "bootstrap-vue";
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";
import vSelect from "vue-select"
import cubeIcon from "@/assets/custom-icons/cubo.svg";
import brazilIcon from "@/assets/custom-icons/brasil.svg";

export default {
  components: {
    BRow,
    BCol,
    CardSemDados,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      cubeIcon,
      brazilIcon
    }
  },
  data() {
    return {
      loadingShow: true,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      estatisticaSelecionada: null,
      estatisticasSelect: [],
      estatisticasPercentual: null,
      vittudePercentual: null
    };
  },
  mounted() {
    this.prepareGraphData()
  },
  methods: {
    prepareGraphData(){
      this.loadingShow = true
      this.estatisticasSelect = this.$props.dados
      this.preparaDadosVittude()
      if(this.$props.dados.length > 0){
        this.estatisticaSelecionada = this.estatisticasSelect[0]
        this.estatisticasPercentual = this.estatisticaSelecionada.percentual.toString().replace(".", ",")
        this.vittudePercentual = this.estatisticasSelect[0].vittudePercentual
      }
      this.loadingShow = false
    },
    preparaDadosVittude(){
      for(let value of this.estatisticasSelect){
        if(value.doenca === "Ansiedade"){
          value.vittudePercentual = "16"
        }else if(value.doenca === "Depressão"){
          value.vittudePercentual = "10,8"
        }else if(value.doenca === "Estresse"){
          value.vittudePercentual = "10,8"
        }
      }
    },
    alteraEstatistica(){
      this.estatisticasPercentual = this.estatisticaSelecionada.percentual.toString().replace(".", ",")
      this.vittudePercentual = this.estatisticaSelecionada.vittudePercentual
    }
  }
};
</script>
<style scoped>
.height-custom{
  height: 212px;
}
.select-custom-estatisticas{
  min-width: 110px;
}
.select-custom-estatisticas::v-deep .vs__dropdown-toggle{
  border: 1px solid #7030A0;
  justify-content: center;
  align-items: center;
}
.select-custom-estatisticas::v-deep .vs__selected-options{
  padding: 0;
  flex-basis: inherit;
  flex-grow: initial;
  padding-left: 5px;
}
.select-custom-estatisticas::v-deep .vs__search{
  margin: 0;
  padding: 0;
  max-height: 0;
  max-width: 0;
}
.select-custom-estatisticas::v-deep .vs__selected{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #7030A0 !important;
}
.select-custom-estatisticas.vs--single.vs--open::v-deep .vs__selected{
  position: relative;
}
.select-custom-estatisticas::v-deep .feather-chevron-down{
  color: #7030A0;
}
.icon-title{
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  color: #B9B9C3;
}
.custom-percentage{
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0px;
  color: #5E5873;
  margin-left: 5px;
}
.responsive-height-broke-text{
  height: 30px;
}
@media (min-width: 1600px) {
  .select-custom-estatisticas::v-deep .vs__selected{
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .responsive-height-broke-text{
    height: 45px;
  }
}
@media (max-width: 767px) {
  .height-custom{
    height: 100%;
    margin-bottom: 1rem;
  }
}
</style>
