<template>
  <b-card v-if="data" no-body>
    <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
      <b-card-header>
        <b-card-title class="mb-0">
          Fase {{ this.FaseSelecionada.id }} - {{ situacaoFase }}
        </b-card-title>
      </b-card-header>
      <b-card-text class="ml-2 mb-0 mt-n1">
        {{ dataFaseInicio }} - {{ dataFaseFim }}
      </b-card-text>
      <vue-apex-charts
        type="radialBar"
        :height="alturaGrafico"
        class="margem-grafico-responsiva"
        :options="graficoGoalOverviewRadialBar.chartOptions"
        :series="graficoGoalOverviewRadialBar.series"
      />
      <b-row class="text-center mx-0">
        <b-col xl="3" md="12" class="responsive-border-top-right py-1">
          <p class="text-muted mb-0 information-label-font">Participantes</p>
          <p class="font-weight-bolder mb-0 number-participants-font">
            {{ this.FaseSelecionada.participantes }}
          </p>
        </b-col>

        <b-col xl="3" md="12" class="responsive-border-top py-1">
          <p class="text-muted mb-0 information-label-font">Recusas</p>
          <p class="font-weight-bolder mb-0 number-participants-font">
            {{ this.FaseSelecionada.recusas }}
          </p>
        </b-col>

        <b-col xl="3" md="12" class="responsive-border-top-right py-1">
          <p class="text-muted mb-0 information-label-font">Respostas</p>
          <p class="font-weight-bolder mb-0 number-participants-font">
            {{ this.FaseSelecionada.respostas }}
          </p>
        </b-col>

        <b-col xl="3" md="12" class="responsive-border-top py-1">
          <p class="text-muted mb-0 information-label-font">Pendentes</p>
          <p class="font-weight-bolder mb-0 number-participants-font">
            {{ pendentesTotal }}
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BOverlay,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5E5873";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BOverlay,
    BCardTitle,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    }
  },
  data() {
    return {
      loadingShow: false,
      pendentesTotal: 0,
      situacaoFase: "",
      dataFaseInicio: "",
      dataFaseFim: "",
      graficoGoalOverviewRadialBar: {
        series: [],
        chartOptions: {},
      },
      alturaGrafico: 512
    };
  },
  beforeMount() {
    this.populacaoCard();
  },
  mounted(){
    window.addEventListener('resize', () => {
      this.alturaGrafico = this.alturaResponsiva();
      this.mudandoElementoLinha();
    });
    this.mudandoElementoLinha();
    this.alturaGrafico = this.alturaResponsiva();
  },
  methods: {
    calculoPorcentagemTooltip(valor, participantes) {
      let porcentagem = ((valor / participantes) * 100).toFixed(0);
      if (porcentagem == 0) {
        porcentagem = this.toFixed((valor / participantes) * 100, 1);
        if (porcentagem == 0.0) {
          porcentagem = ((valor / participantes) * 100).toFixed(0);
        }
      }
      return porcentagem;
    },
    toFixed(num, fixed) {
      let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
    formatarData(data) {
      const objetoData = this.parseDate(data);
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },
    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },
    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return "Não iniciado";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return "Em andamento";
      } else if (objetoDataFim < objetoDataNow) {
        return "Finalizado";
      }
    },

    mudandoElementoLinha(){
      const parentElement = document.querySelector(".apexcharts-radial-series");

      if (parentElement) {
        const pathElement = parentElement.querySelector('path');
        if (pathElement) {
          pathElement.setAttribute('stroke-width', 10);
        }
      }
    },

    chamadaGrafico() {
      return {
        chart: {
          height: this.alturaGrafico,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ["#34B313"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: 35,
              },
              value: {
                offsetY: -10,
                color: $textHeadingColor,
                fontFamily: "Montserrat",
                fontSize: "4rem",
                fontWeight: "500",
              },
              total: {
                show: true,
                label: "Completo",
                fontSize: "1.5rem",
                fontFamily: "Montserrat",
                fontWeight: 0,
                color: "#B9B9C3",
              }
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "horizontal",
            gradientToColors: ["#2772BF"],
            inverseColors: false,
            stops: [0, 50],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            fontSize: "14px",
          },
          custom: function () {
            return (
              '<div style="margin:20px;">' +
              "<div> 0% Recusas </div>" +
              "<div> 0% Respostas </div>" +
              "</div>"
            );
          },
        },
      };
    },
    populacaoCard() {
      this.pendentesTotal =
        this.FaseSelecionada.participantes -
        (this.FaseSelecionada.respostas + this.FaseSelecionada.recusas);
      if (this.pendentesTotal < 0) {
        this.pendentesTotal = 0;
      }

      this.situacaoFase = this.progressoFase(
        this.FaseSelecionada.inicio,
        this.FaseSelecionada.termino
      );
      this.dataFaseInicio = this.formatarData(this.FaseSelecionada.inicio);
      this.dataFaseFim = this.formatarData(this.FaseSelecionada.termino);
      let porcentagemRecusas = this.calculoPorcentagemTooltip(
        this.FaseSelecionada.recusas,
        this.FaseSelecionada.participantes
      );
      let porcentagemRespostas = this.calculoPorcentagemTooltip(
        this.FaseSelecionada.respostas,
        this.FaseSelecionada.participantes
      );
      this.graficoGoalOverviewRadialBar.chartOptions = this.chamadaGrafico();

      this.graficoGoalOverviewRadialBar.series = [
        (
          ((this.FaseSelecionada.respostas) /
            this.FaseSelecionada.participantes) *
          100
        ).toFixed(0),
      ];

      this.graficoGoalOverviewRadialBar.chartOptions.tooltip.custom = [
        function () {
          return (
            '<div style="margin:20px;">' +
            "<div>" +
            porcentagemRecusas +
            "% Recusas </div>" +
            "<div>" +
            porcentagemRespostas +
            "% Respostas </div>" +
            "</div>"
          );
        },
      ];
      this.loadingShow = true;
    },
    alturaResponsiva(){
      if(window.innerWidth >= 1710) {
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 512;
      } else if(window.innerWidth >= 1601 && window.innerWidth <= 1710){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 435;
      } else if(window.innerWidth >= 1367 && window.innerWidth <= 1600){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 390;
      } else if(window.innerWidth >= 1200 && window.innerWidth <= 1366){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 330;
      } else if(window.innerWidth >= 1120 && window.innerWidth <= 1199){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 400;
      } else if(window.innerWidth >= 992 && window.innerWidth <= 1119){
        this.atualizaGraficoFontSize("3.5rem", "1.5rem");
        return 360;
      } else if(window.innerWidth >= 900 && window.innerWidth <= 991){
        this.atualizaGraficoFontSize("3.5rem", "1.5rem");
        return 320;
      } else if(window.innerWidth >= 800 && window.innerWidth <= 899){
        this.atualizaGraficoFontSize("3rem", "1rem");
        return 285;
      } else if(window.innerWidth >= 768 && window.innerWidth <= 799){
        this.atualizaGraficoFontSize("3rem", "1rem");
        return 270;
      } else if(window.innerWidth >= 576 && window.innerWidth <= 767){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 435;
      } else if(window.innerWidth >= 415 && window.innerWidth <= 575){
        this.atualizaGraficoFontSize("4rem", "1.5rem");
        return 435;
      } else if(window.innerWidth >= 320 && window.innerWidth <= 414){
        this.atualizaGraficoFontSize("3.5rem", "1.5rem");
        return 350;
      } else if(window.innerWidth <= 319){
        this.atualizaGraficoFontSize("3rem", "1rem");
        return 270;
      }
    },
    atualizaGraficoFontSize(fontPercentage, fontLabel){
      this.graficoGoalOverviewRadialBar.chartOptions.plotOptions.radialBar.dataLabels.value.fontSize = fontPercentage;
      this.graficoGoalOverviewRadialBar.chartOptions.plotOptions.radialBar.dataLabels.total.fontSize = fontLabel;
    }
  },
  watch: {
    FaseSelecionada: function () {
      this.populacaoCard();
    },
  },
};
</script>
<style scoped>
.margem-grafico-responsiva{
  margin-bottom: 14px;
}

.responsive-border-top-right{
  border-top: 1px solid #ebe9f1;
  border-right: 1px solid #ebe9f1;
}

.responsive-border-top{
  border-top: 1px solid #ebe9f1;
}

.number-participants-font{
  font-size: 18px;
}

@media (min-width: 1200px) and (max-width: 1710px){
  .col-xl-3{
    flex:0 0 50%;
    max-width:50%;
  }
}

@media (min-width: 1200px) and (max-width: 1366px){
  .margem-grafico-responsiva{
    margin-top: 35px;
    margin-bottom: 23px;
  }
}

@media (max-width: 1199px){
  .responsive-border-top-right{
    border-right: none;
  }
  .information-label-font{
    font-size: 16px;
  }

  .number-participants-font{
    font-size: 24px;
  }
}

@media (min-width: 1120px) and (max-width: 1199px){

  .margem-grafico-responsiva{
    margin-top: 55px;
    margin-bottom: 48px;
  }

  .number-participants-font{
    font-size: 24px;
    margin-top: 12px;
  }

}

@media (min-width: 992px) and (max-width: 1119px){

  .margem-grafico-responsiva{
    margin-top: 68px;
    margin-bottom: 56px;
  }

  .number-participants-font{
    margin-top: 14px;
  }

}

@media (min-width: 900px) and (max-width: 991px){

  .margem-grafico-responsiva{
    margin-top: 90px;
    margin-bottom: 70px;
  }

  .number-participants-font{
    margin-top: 14px;
  }

  .information-label-font{
    font-size: 20px;
  }

  .number-participants-font{
    font-size: 28px;
    margin-top: 16px;
  }

}

@media (min-width: 768px) and (max-width: 899px){

  .margem-grafico-responsiva{
    margin-top: 92px;
    margin-bottom: 80px;
  }

  .number-participants-font{
    margin-top: 14px;
  }

  .information-label-font{
    font-size: 22px;
  }

  .number-participants-font{
    font-size: 30px;
    margin-top: 20px;
  }

}

@media (min-width: 800px) and (max-width: 893px){
  .margem-grafico-responsiva{
    margin-top: 80px;
    margin-bottom: 70px;
  }
}

@media (min-width: 768px) and (max-width: 799px){
  .margem-grafico-responsiva{
    margin-top: 85px;
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) and (max-width: 799px){
  .margem-grafico-responsiva{
    margin-top: 85px;
    margin-bottom: 75px;
  }
}

@media (max-width: 767px){

  .information-label-font{
    font-size: 16px;
  }

  .number-participants-font{
    font-size: 24px;
    margin-top: 10px;
  }
}
</style>
