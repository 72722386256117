<template>
  <div>
    <div
      v-if="dados.length > 0" 
    >
      <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
      <vue-perfect-scrollbar
        class="mx-2 pr-2 scroll-area"
        :settings="settings"
      >
        <div>
          <b-row
            v-for="item in dadosParaOComponenteFuncionar"
            :key="item.id"
            class="no-gutters mb-1 align-items-center"
          >
            <b-col
              xl="4"
              lg="4"
              md="12"
              class="text-left text-lg-right pr-1"
            >
              <div>{{ item.descricao }}</div>
            </b-col>
            <b-col
              xl="8"
              lg="8"
              md="12"
            >
              <b-progress
                max="100"
                class="progress-bar-info"
              >
                <b-progress-bar
                  v-if="item.porcentagem >= porcentagemResponsivo"
                  :value="item.porcentagem"
                  :label="item.porcentagem + '%'"
                  class="cor-barra-gradiente"
                />
                <b-progress-bar
                  v-else
                  :value="item.porcentagem"
                  :label="''"
                  class="cor-barra-gradiente"
                />
              </b-progress>
            </b-col>
          </b-row>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div
      v-else
      class="scroll-area d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import { BProgress, BCardTitle, BCardHeader, BProgressBar, BCardText, BCard, BRow, BCol, BOverlay } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CardSemDados from "@/views/components/custom/dashboard-saude-mental/dass-21/CardSemDados.vue";

export default {
  components: {
    BCard,
    BProgress,
    BProgressBar,
    BCardText,
    BCardHeader,
    BRow,
    BCol,
    VuePerfectScrollbar,
    BOverlay,
    BCardTitle,
    CardSemDados
  },
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0
      },
      loadingShow: true,
      dadosParaOComponenteFuncionar: [],
      max: 100,
      porcentagemResponsivo: this.visibilidadeComprimento(),
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.3,
        wheelPropagation: false
      }
    }
  },
  async mounted(){
    await this.preencheGrafico()
    window.addEventListener('resize', () => {
      this.porcentagemResponsivo = this.visibilidadeComprimento();
    });
  },
  methods: {
    preencheGrafico(){
      this.preparaPorcentagem()
      this.loadingShow = false;
    },
    preparaPorcentagem(){
      this.dadosParaOComponenteFuncionar = this.$props.dados;
      for(const element of this.dadosParaOComponenteFuncionar){
          element['porcentagem'] = Math.floor((element['quantidade']/element['total'])*100)
      }
    },
    visibilidadeComprimento(){
      if(window.innerWidth >= 400 && window.innerWidth <= 470){
        return 10;
      }
      if(window.innerWidth < 400){
        return 15;
      }
      return 5;
    }
  }
}
</script>
<style scoped>
.cor-barra-gradiente{
  background-image: linear-gradient(to right, #3A60B7, #2C2679);
}
.scroll-area{
  height: 185px;
}
@media (max-width: 767px) {
  .scroll-area{
    height: 100%;
    margin-bottom: 1rem;
  }
}
</style>
