<template>
    <div>
      <div v-if="selectFases && selectFases.length > 0 " >
        <b-overlay
          v-if="loadingShow"
          :show="loadingShow"
          rounded="lg"
          opacity="0.6"
          class="mt-2"
        />
        <b-row class="no-gutters">
          <b-col sm="12" cols="12" class="d-flex justify-content-center align-items-center">
            <fase-grafico :dados="selectedFase"/>
          </b-col>
          <b-col
            sm="12"
            cols="12"
            class=" pt-lg-1 my-auto pt-sm-0 pt-1"
          >
            <div
              class="d-flex justify-content-center align-items-center"
            >
              <v-select
                v-model="selectedFase"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="selectFases"
                :clearable="false"
                @input="atualizaFaseSelecionadaDoDashboard"
                class="select-custom-fases"
                label="descricao"
                item-text="descricao"
                item-value="descricao"
              >
                <span slot="no-options">Nenhuma fase encontrada.</span>
              </v-select> 
            </div>
          </b-col>
        </b-row>
        <b-row class="text-center mx-0 mt-1">
          <b-col xl="3" md="12" class="padding-div responsive-border-top-right py-1">
            <p class="text-muted mb-0 information-label-font">Participantes</p>
            <p class="font-weight-bolder mb-0 number-participants-font">
              {{ selectedFase.participantes }}
            </p>
          </b-col>
    
          <b-col xl="3" md="12" class=" padding-div responsive-border-top py-1">
            <p class="text-muted mb-0 information-label-font">Recusas</p>
            <p class="font-weight-bolder mb-0 number-participants-font">
              {{ selectedFase.recusas }}
            </p>
          </b-col>

          <b-col xl="3" md="12" class="padding-div responsive-border-top-right py-1">
            <p class="text-muted mb-0 information-label-font">Pendentes</p>
            <p class="font-weight-bolder mb-0 number-participants-font">
              {{ pendentesTotal }}
            </p>
          </b-col>

          <b-col xl="3" md="12" class="padding-div responsive-border-top py-1">
            <p class="text-muted mb-0 information-label-font">Finalizados</p>
            <p class="font-weight-bolder mb-0 number-participants-font">
              {{  selectedFase.respostas }}
            </p>
          </b-col>
        </b-row>      
      </div>
      <div
        v-else
        class="height-custom d-flex align-items-center justify-content-center"
      >
         <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/> 
      </div>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BOverlay
  } from "bootstrap-vue";
  import CardSemDados from "@/views/components/custom/dashboard-saude-mental/phq-9/CardSemDados.vue";
  import FaseGrafico from "@/views/components/custom/dashboard-saude-mental/phq-9/FaseGrafico.vue";
  import vSelect from "vue-select"
  
  export default {
    components: {
      CardSemDados,
      BRow,
      BCol,
      BOverlay,
      FaseGrafico,
      vSelect
    },
    props: {
      dados: {
        type: Object,
        required: true
      }
    },
    computed: {
      pendentesTotal() {
        this.pendetesTotais =
        this.dados.selectedFase.participantes - (this.dados.selectedFase.respostas + this.dados.selectedFase.recusas);
        if (this.pendetesTotais < 0) {
          this.pendetesTotais = 0;
        }

        return this.pendetesTotais
      }
    },
    data() {
      return {
        loadingShow: true,
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0,
        },
        selectFases: null,
        selectedFase: null,
        graphSeries: [],
        pendetesTotais: 0
      };
    },
    mounted() {
      this.selectFases = this.$props.dados.allFases;
      this.selectedFase = this.$props.dados.selectedFase;

      this.loadingShow = false
    },
    methods: {
      atualizaFaseSelecionadaDoDashboard(objectFase){
        this.$emit("atualizaFaseSelecionadaDoDashboard", objectFase);
      }
    }
  };
  </script>
  <style scoped>
  .height-custom{
    height: 212px;
  }
  .select-custom-fases{
    min-width: 130px;
  }
  .select-custom-fases::v-deep .vs__dropdown-toggle{
    border: 1px solid #7030A0;
    justify-content: space-between;
    align-items: center;
  }
  .select-custom-fases::v-deep .vs__selected-options{
    padding: 0;
    flex-basis: inherit;
    flex-grow: initial;
    padding-left: 5px;
  }
  .select-custom-fases::v-deep .vs__search{
    margin: 0;
    padding: 0;
    max-height: 0;
    max-width: 0;
  }
  .select-custom-fases::v-deep .vs__selected{
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #7030A0 !important;
  }
  .select-custom-fases.vs--single.vs--open::v-deep .vs__selected{
    position: relative;
  }
  .select-custom-fases::v-deep .feather-chevron-down{
    color: #7030A0;
  }
  .subtitle-font{
    color: #B9B9C3;
    font-size: 12px;
    font-weight: 400;
  }
  .porcentage-font{
    color: #5E5873;
    font-size: 18px;
    font-weight: 700;
  }
  .padding-div{
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: 768px) and (max-width: 1350px){
  .col-xl-3{
    flex:0 0 50%;
    max-width:50%;
  }
}
  @media (min-width: 1600px) {
    .select-custom-fases::v-deep .vs__selected{
      font-size: 13px;
    }
    .subtitle-font{
      font-size: 14px;
    }
    .porcentage-font{
      font-size: 20px;
    }
  }
  @media (max-width: 991px) {
    .subtitle-font{
      font-size: 10px;
    }
    .porcentage-font{
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    .height-custom{
      height: 100%;
      margin-bottom: 2rem;
    }
    .subtitle-font{
      font-size: 12px;
    }
    .porcentage-font{
      font-size: 18px;
    }
  }
  </style>
  