<template>
  <section>
    <b-overlay
      v-if="!loadingShow"
      class="mt-5"
      :show="!loadingShow" 
      rounded="lg"
      opacity="0.6"
    />
    <b-overlay
      :show="loadingAtualizationShow"
      rounded="lg"
      opacity="0.6"
    >
      <b-row v-if="loadingShow" class="custom-row-gutter"> 
        <b-col md="4" sm="12" class="custom-col-gutter">
          <b-card no-body>
            <div class="altura_linha_1">
              <titulo-generico
                    titulo="Fase"
                    :subtitulo="'Período da fase '+ progressoFase(faseSelecionada.inicio, faseSelecionada.termino) +' ('+formatarData(faseSelecionada.inicio)+' - '+formatarData(faseSelecionada.termino)+')'"
                  />

                  <fase
                    :dados="dadosCardFase"
                    @atualizaFaseSelecionadaDoDashboard="atualizaFaseSelecionadaDoDashboard"
                  />
            </div>
          </b-card>
        </b-col>
      
        <b-col 
          lg="8"
          md="8" 
          class="custom-col-gutter"
        >
          <b-card no-body>
            <div class="altura_linha_1">
              <titulo-generico titulo="Escala de Respostas"/>
                <escala-respostas
                  :dados="dadosCardEscalaRespostas"
                  :key="keyAtualizacaoCards"
                />
            </div>
            
          </b-card> 
        </b-col>

        <b-col 
            v-if="dashboardMaiorDificuldade" 
            lg="6" 
            md="6" 
            class="custom-col-gutter"
        >
            <b-card no-body>
              <div class="altura_linha_2">
                <titulo-generico titulo="Áreas de maior dificuldade"/>
                <area-dificuldade
                  :dados="dadosAreaMaiorDificuldade"
                  :key="keyAtualizacaoCards"
                  :DataCicloTempoReal="dataCicloTempoReal"
                />
              </div>
            </b-card>
        </b-col>

        <b-col
          v-if="dashboardMaiorDificuldade"
          lg="6"
          md="6"
          class="custom-col-gutter"
        >
          <b-card no-body>
            <div class="altura_linha_2">
              <titulo-generico titulo="Maiores dificuldade no trabalho"/>
              <taxa-resposta-maior-dificuldade
                :dados="dadosMaiorDificuldadeTrabalho"
                :FaseSelecionada="faseSelecionada"
                :semRespostaNovaPergunta="verificaNovaPerguntaSaudeMentalTotal"
              />
            </div>
          </b-card>
        </b-col>
        <b-col 
            v-if="dashboardMaiorDificuldade == false && dashboardMaiorDificuldade != null"
            lg="12" 
            md="12" 
            class="custom-col-gutter mb-md-0 mb-2"
        >
            <b-card no-body>
              <div class="altura_linha_2">
                <titulo-generico 
                  titulo="Áreas de maior dificuldade"
                />
                <area-dificuldade
                  :dados="dadosAreaMaiorDificuldade"
                  :key="keyAtualizacaoCards"
                  :DataCicloTempoReal="dataCicloTempoReal"
                />
              </div>
            </b-card>
        </b-col>
        <b-col 
          lg="6"
          md="12"
          class="custom-col-gutter"
        >
          <b-card no-body>
            <div class="altura_linha_3">
              <titulo-generico 
                titulo="Taxa de Resposta por Equipe"
               
              />
              <taxa-resposta-equipe
                :dados="dadosTaxaRespostaEquipe"
                :FaseSelecionada="faseSelecionada"
              />
            </div>
          </b-card>
        </b-col>

        <b-col 
          lg="6" 
          md="12"
          class="custom-col-gutter"
          >
            <b-card no-body>
              <div class="altura_linha_3">
                <titulo-generico titulo="Estatísticas dos níveis de depressão"/>
                <estatisticas-depressao
                  :dados="dadosCardNiveisDepressao"
                  :DataCicloTempoReal="dataCicloTempoReal"
                />
              </div>
            </b-card>
          </b-col>

          <b-col cols="12" class="custom-col-gutter">
            <b-card
              no-body
            >
              <div
                class="altura_linha_5"
              >
                <titulo-generico
                  titulo="NPS"
                  tooltipText="Pesquisa que utiliza dados para avaliar a satisfação com a experiência que tiveram no uso da Cora."
                />
                <nps
                  :key="keyAtualizacaoCards"
                  :dados="dadosCardIndicacao"
                />
              </div>
            </b-card>
          </b-col>
          <b-col
            v-if="typeof this.$props.dadosFullScreen === 'undefined'"
            class="mb-1 custom-col-gutter"
            cols="12"
          >
          <dashboard-redirect-section
            :routeName="rotaDeRedirecionamento"
            :dadosRedirecionamento="dadosRedirecionamento"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { BRow, BCol, BOverlay,BCard } from "bootstrap-vue";
import TituloGenerico from "@/views/components/custom/dashboard-saude-mental/TituloGenerico.vue";
import EscalaRespostas from "@/views/components/custom/dashboard-saude-mental/phq-9/EscalaRespostas.vue";
import DashboardRedirectSection from "@/views/components/custom/dashboard-saude-mental/DashboardRedirectSection.vue";
import Fase from "@/views/components/custom/dashboard-saude-mental/phq-9/Fase.vue";
import CicloAndamento from "@/views/components/custom/dashboard-saude-mental/phq-9/CicloAndamento.vue";
import Nps from "@/views/components/custom/dashboard-saude-mental/phq-9/NPS.vue";
import TaxaRespostaEquipe from "@/views/components/custom/dashboard-saude-mental/phq-9/TaxaRespostaEquipe.vue";
import AreaDificuldade from "@/views/components/custom/dashboard-saude-mental/phq-9/AreaDificuldade.vue";
import EstatisticasDepressao from "@/views/components/custom/dashboard-saude-mental/phq-9/EstatisticasDepressao.vue";
import CiclosRealizados from "@/views/components/custom/dashboard-saude-mental/phq-9/CiclosRealizados.vue";
import TaxaRespostaMaiorDificuldade from "@/views/components/custom/dashboard-saude-mental/phq-9/TaxaRespostaMaiorDificuldade.vue";
import { getUserData } from "@/auth/utils";
import { modalGenericModel } from '@/libs/sweetalerts';

export default {
  components: {
    BRow,
    BCol,
    Fase,
    DashboardRedirectSection,
    BCard,
    BOverlay,
    CicloAndamento,
    EscalaRespostas,
    TaxaRespostaMaiorDificuldade,
    TaxaRespostaEquipe,
    AreaDificuldade,
    EstatisticasDepressao,
    TituloGenerico,
    CiclosRealizados,
    Nps
  },

  props: {
    empresaSelecionada: {
      type: Object,
      default() {
        return {};
      },
    },
    rotaDeRedirecionamento: {
      type: String,
      required: true
    },
    dadosFullScreen: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      keyAtualizacaoCards: 0,
      loadingAtualizationShow: false,
      fasesDaEmpresa: [],
      faseSelecionada: null,
      loadingShow: false,
      dataCicloTempoReal: null,
      dados: {},
      dashboardMaiorDificuldade:null,
      dadosRedirecionamento: {},
      verificaNovaPerguntaSaudeMentalTotal: null,
      dadosCardFase: {
        allFases: [],
        selectedFase: null
      },
      dadosCardEstatisticasNiveisDepressao: [],
      dadosTaxaRespostaEquipe: [],
      dadosCardEscalaRespostas: [
        {
          descricao: "Total",
          dados: []
        },
        {
          descricao: "setor",
          dados: []
        },
        {
          descricao: "gerencia",
          dados: []
        },
        {
          descricao: "genero",
          dados: []
        },
        {
          descricao: "faixa_etaria",
          dados: []
        },
        {
          descricao: "lideres",
          dados: []
        }
      ],
      dadosCardEscalaRespostasTotal: [],
      dadosCardNiveisDepressao: [],
      dadosCardEscalaRespostasGerencia: [],
      dadosCardEscalaRespostasSetor: [],
      dadosCardEscalaRespostasFaixaEtaria: [],
      dadosCardEscalaRespostasLideres: [],
      dadosCardEscalaRespostasGenero: [],
      dadosCardMaioresDificuldadesNoTrabalho: [],
      dadosCardIndicacao: [],
      dadosAreaMaiorDificuldade: [],
      dadosMaiorDificuldadeTrabalho: [],
      data: {
        cicloAndamento: {},
        taxaRespostaEquipe: {},
        ciclosRealizados: {},
        escalaRespostas: {},
        estatisticasDepressao: {},
        areaDificuldade: {}
      },
    };
  },

  async mounted() {
    await this.validaExistenciaFase();
  },

  methods: {

    async buscaDados(){
      await Promise.all([
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaTotal, "dadosCardEscalaRespostasTotal"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaSetor,"dadosCardEscalaRespostasSetor"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaGerencia, "dadosCardEscalaRespostasGerencia"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaGenero, "dadosCardEscalaRespostasGenero"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaFaixaEtaria, "dadosCardEscalaRespostasFaixaEtaria"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardRespostasEscalaLideres, "dadosCardEscalaRespostasLideres"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardMaiorDificuldadeTrabalho, "dadosCardMaioresDificuldadesNoTrabalho"),
        this.buscaDadosCard(this.$api.DashboarbSaudeMentalCardIndicacaos, "dadosCardIndicacao"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardAreaMaiorDificuldade, "dadosAreaMaiorDificuldade"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardMaiorDificuldadeTrabalho, "dadosMaiorDificuldadeTrabalho"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardTaxaRespostaEquipe, "dadosTaxaRespostaEquipe"),
        this.buscaDadosCard(this.$api.DashboardSaudeMentalCardEstatisticasNiveisDepressao, "dadosCardNiveisDepressao"),
      ]);

      this.dadosCardEscalaRespostas[0].dados = this.dadosCardEscalaRespostasTotal
      this.dadosCardEscalaRespostas[1].dados = this.dadosCardEscalaRespostasSetor
      this.dadosCardEscalaRespostas[2].dados = this.dadosCardEscalaRespostasGerencia
      this.dadosCardEscalaRespostas[3].dados = this.dadosCardEscalaRespostasGenero
      this.dadosCardEscalaRespostas[4].dados = this.dadosCardEscalaRespostasFaixaEtaria
      this.dadosCardEscalaRespostas[5].dados = this.dadosCardEscalaRespostasLideres
    
    },

    async validaExistenciaFase() {
      let rota;
      if(this.$props.empresaSelecionada.id_empresa){
        rota = this.$helpers.preparaRotaComParametros(
          this.$api.DashboardSaudeMentalCiclos,
          "{idEmpresa}",
          this.$props.empresaSelecionada.id_empresa
        );
      }else{
        const usuario = getUserData();
        if (!usuario?.empresa.id_empresa) {
          this.$router.push({ name: "error-404" });
        }
        rota = this.$helpers.preparaRotaComParametros(
          this.$api.DashboardSaudeMentalCiclos,
          "{idEmpresa}",
          usuario.empresa.id_empresa
        );
      }

      await this.$http
      .get(rota)
      .then(async (res) => {
        if (res.status == 204) {
          modalGenericModel(
            'Resultados não encontrados',
            'A empresa buscada não possui resultados para este questionário',
            require('@/assets/custom-icons/exclamationMark.png'),
            'Entendi'
          ).then(() => {
            this.$emit('retorna-listagem-empresa')
          });
        } else {
          this.dadosCardFase = {
            allFases: res.data
          }
          this.verificaDashboardFullScreen()
          this.dados = res.data[0];

          await this.buscaDados().then(async () => {
            this.preparaArrayFullScreen()
            // this.loadingShow = false
          })
          this.verificaNovaPerguntaSaudeMental();
          this.preencheDados(res.data);
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    verificaNovaPerguntaSaudeMental(){
        let params = {
          idEmpresa: this.dados.id_empresa,
          idFase: this.dados.id_fase
        };

        this.$http
          .get(this.$api.VerificaNovaPerguntaSaudeMental, { params })
          .then((res) => {
            this.verificaNovaPerguntaSaudeMentalTotal = res.data[0];
            this.verificaSeExisteNovaPerguntaMaiorDificuldadeTrabalho();
        })
        .catch((_error) => {
          this.$router.push({ name: "error-404" });
        });

      },

      async buscaDadosCard(apiLink, stringPropDados){
      const params = {
        idEmpresa: this.dados.id_empresa,
        idFase: this.dados.id_fase
      }
      await this.$http
      .get(apiLink, { params })
      .then((res) => {
        if (res.status == 204) {
          this.$set(this, stringPropDados, [])
        } else {
          this.$set(this, stringPropDados, res.data)
        }

      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    verificaSeExisteNovaPerguntaMaiorDificuldadeTrabalho(){
      let params = {
          idEmpresa: this.dados.id_empresa,
          idFase: this.dados.id_fase
        };

      this.$http
      .get(this.$api.DashboardSaudeMentalCardMaiorDificuldadeTrabalho, { params })
      .then((res) => {
        if (res.status == 204 && res.data.length == 0 && this.verificaNovaPerguntaSaudeMentalTotal.total == 0) {
          this.dashboardMaiorDificuldade = false;
        } else if(res.data.length > 0){
          this.dashboardMaiorDificuldade = true;
        }else if(this.verificaNovaPerguntaSaudeMentalTotal.total > 0 && res.data.length == 0){
          this.dashboardMaiorDificuldade = true;
        }
      })
      .catch((_error) => {
        this.$router.push({ name: "error-404" });
      });
    },

    preencheDados(data) {
      this.fasesDaEmpresa = data;
      this.faseSelecionada = this.fasesDaEmpresa[0];
      this.faseSelecionada.id = 1;
      this.dataCicloTempoReal = this.progressoFase(
        this.faseSelecionada.inicio,
        this.faseSelecionada.termino
      );
      this.loadingShow = true;
    },

    buscarDadosCardCiclosPorCicloSelecionado(cicloSelecionado) {
      this.faseSelecionada = cicloSelecionado;
    },

    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },

    formatarData(data) {
      const objetoData = data ? this.parseDate(data) : new Date();
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },

    preparaArrayFullScreen(){
      this.dadosRedirecionamento = {
        idEmpresa: this.dados.id_empresa,
        nomeQuestionario: "PHQ-9",
        idFase: this.dados.id_fase
      }
    },

    verificaDashboardFullScreen(){
      if(
        typeof this.$props.dadosFullScreen !== 'undefined'
      ){
        this.dadosCardFase.selectedFase = this.dadosCardFase.allFases.find(
          (item) => item.id_fase == this.$props.dadosFullScreen.idFase
        )
      }else{
        this.dadosCardFase.selectedFase = this.dadosCardFase.allFases[0]
      }
    },

    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return "não iniciada";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return "em andamento";
      } else if (objetoDataFim < objetoDataNow) {
        return "finalizada";
      }
    },

    async atualizaFaseSelecionadaDoDashboard(objectFase){
      this.loadingAtualizationShow = true
      this.dadosCardFase.selectedFase = objectFase
      await this.buscaDados({
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.dados.id_empresa, 
      });
      this.keyAtualizacaoCards++
      this.preparaArrayFullScreen()
      this.loadingAtualizationShow = false
    },

  }
};
</script>

<style lang="scss" >
.fonte14 {
  font-size: 14px;
}

.altura_linha_1{
  height:395px;
}

.altura_linha_2{
  height: 340px;
}

.altura_linha_3{
  height: 415px;
}

.custom-col-gutter {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.custom-row-gutter{
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.altura_linha_5{
  height: 116px;
}

.fonte18 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.clicavel {
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .altura_linha_3{
    height: 365px;
  }

  .altura_linha_2{
    height: 285px;
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .altura_linha_1{
    height: 440px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .altura_linha_2{
    height:340px;
  }
}

@media (max-width: 991px) {
  .altura_linha_3{
    height: 100%;
  }
}


@media(max-width: 767px){
  .altura_linha_1{
    height: 100%;
  }
  .altura_linha_2{
    height: 100%;
  }
  .altura_linha_5{
    height: 100%;
  }

}
</style>
